import { render } from "./index.vue?vue&type=template&id=324a47ea&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=324a47ea&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-324a47ea"
/* hot reload */
if (module.hot) {
  script.__hmrId = "324a47ea"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('324a47ea', script)) {
    api.reload('324a47ea', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=324a47ea&scoped=true", () => {
    api.rerender('324a47ea', render)
  })

}

script.__file = "src/views/main/super-tomato/index.vue"

export default script