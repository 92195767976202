import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/views/main/Index.vue'
import Tips from '@/views/tips/Index.vue'
import { getInvStatus } from '@/utils/index'
import { Toast } from 'vant'
import { setBrand } from '@/utils/index'
import { _getOrderNo } from '@/utils/storage'

const routes = [
    {
      path: '/'
    },
    {
      path: '/main',
      name: 'main',
      component: Main
    },
    {
      path: '/tips/:tipsType',
      name: 'tips',
      component: Tips,
      props: true
    },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    const { orderNo, brand, info } = to.query
    setBrand(brand)
    getInvStatus({ orderNo, info }).then(res => {
      if (!res) return
      return next(res)
    })
  } else {
    const hasOrderInfo = !!_getOrderNo()
    if (!hasOrderInfo) {
      Toast.fail('缺少订单信息')
      return next('')
    }
    next()
  }
})

export default router