<template>
  <div class="mountain-outside">
    <div class="wrapper">
      <img class="form-title" src="@/assets/images/mountain-outside/form-title.png" alt="">
      <div class="main">
        <invoice-form ref="formRef" v-model:showMore="showMore" @validate="val => canSubmit = val">
          <template v-slot:more>
            <more-info v-model="showMore" />
          </template>
        </invoice-form>
        <submit-btn :disabled="!canSubmit" :is-fixed="!showMore" @submit="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import InvoiceForm from '@/components/invoiceForm.vue'
import MoreInfo from '@/components/moreInfo.vue'
import SubmitBtn from '@/components/submitBtn.vue'

export default defineComponent({
  components: {
    InvoiceForm,
    MoreInfo,
    SubmitBtn
  },
  setup() {
    const showMore = ref(false)
    const canSubmit = ref(false)
    const formRef = ref(null)
    const onSubmit = () => formRef.value.submitForm()

    return {
      showMore,
      canSubmit,
      formRef,
      onSubmit
    }
  }
})
</script>

<style lang="less" scoped>
.mountain-outside {
  height: 100vh;
  padding: 30px 10px 80px;
  box-sizing: border-box;
  overflow: hidden;
  background: url('~@/assets/images/mountain-outside/bg.png') no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;

  .wrapper {
    height: 100%;
    text-align: center;
    overflow: auto;

    .form-title {
      width: 250px;
      margin-top: 28px;
    }

    .main {
      padding: 5px 15px 75px;

      ::v-deep(.van-cell-group) {
        background: none;

        .van-cell {
          padding: 24px 10px 4px;
          background: none;

          .van-field__label {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 500;
            margin-right: 0;
            font-size: 14px;
            background: url('~@/assets/images/mountain-outside/form-label-bg.png') left no-repeat;
            background-size: contain;
            margin-right: 15px;
            height: 26px;
          }
          .van-cell__value {
            font-size: 15px;
          }

          &::after {
            left: 90px;
            border-color: #020202;
            border-bottom-style: dashed;
          }

          &:last-child::after {
            display: block;
          }

          .radio-icon {
            width: 20px;
            height: 20px;
          }

          .van-radio__label {
            margin-left: 4px;
          }
        }
      }

      ::v-deep(.invoiceTitle) {
        .chooseTitle {
          padding-left: 5px;
          color: #409610;
        }
      }
    }
  }
}
</style>