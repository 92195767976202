<template>
  <div class="tips-sxym">
    <div v-if="tipsType === 'overtime'" class="overtime">
      <img class="fail-title" src="@/assets/images/sxym/fail-title.png" />
      <div class="fail-tips">已超过30天，请联系客服开具</div>
    </div>
    <div v-if="tipsType === 'unsynchronized'" class="unsynchronized">
      <img class="sync-title" src="@/assets/images/sxym/fail-title.png" />
      <div class="sync-tips">账单同步中，建议3分钟后再次申请</div>
    </div>
    <div v-if="tipsType === 'wait'" class="wait">
      <img class="wait-title" src="@/assets/images/sxym/success-title.png" />
      <div class="wait-tips">
        <img class="zh-cn" src="@/assets/images/sxym/wait-tips-zhcn.png" />
        <img class="en" src="@/assets/images/sxym/wait-tips-en.png" />
      </div>
    </div>
    <div v-if="tipsType === 'success'" class="success">
      <div class="tips">电子发票与纸质发票具有同等法律效力，可支持报销入账</div>
      <div class="wrapper">
        <img class="detail-title" src="@/assets/images/sxym/detail-title.png" />
        <div class="detail">
          <div class="row" v-for="row in detail" :key="row.field">
            <div class="label">{{ row.label }}</div>
            <div class="value">{{ row.value }}</div>
          </div>
          <img class="view-invoice" src="@/assets/images/sxym/view-invoice.png" @click="previewInvoice" />
        </div>
      </div>
    </div>
    <div v-if="tipsType === 'failed'" class="failed">
      <img class="fail-title" src="@/assets/images/sxym/fail-title.png" />
      <div class="fail-tips">
        <img class="zh-cn" src="@/assets/images/sxym/fail-tips-zhcn.png" />
        <img class="en" src="@/assets/images/sxym/fail-tips-en.png" />
      </div>
    </div>
    <!-- <van-popup
      v-model:show="showHotLine"
      style="background-color: transparent;"
      teleport="body"
      safe-area-inset-top
      safe-area-inset-bottom
      :close-on-click-overlay="false"
    >
      <div class="contact-modal">
        <div class="contents">
          <img class="hotline-title" src="@/assets/images/taier/hotline-title.png" />
          <div class="desc">
            <span>如有需要请拨打客服热线</span>
            <span class="mark">400-6092-822</span>
          </div>
          <div class="desc">
            <span>接线时间: 周一到周日</span>
            <span class="mark">9:30-18:00</span>
          </div>
          <div class="desc">(节假日除外)</div>
        </div>
        <div class="btns">
          <div class="btn cancel" @click="showHotLine = false">取消</div>
          <div class="btn confirm" @click="dial">拨打</div>
        </div>
      </div>
    </van-popup> -->
    <img class="logo" src="@/assets/images/sxym/logo.png" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import useInvoiceDetail from '@/composables/useInvoiceDetail'

export default defineComponent({
  props: {
    tipsType: {
      type: String
    }
  },
  setup(props) {
    const showHotLine = ref(false)
    const { detail, previewInvoice } = useInvoiceDetail(props)

    const dial = () => {
      window.location.href = 'tel:4006092822'
      showHotLine.value = false
    }

    return {
      detail,
      showHotLine,
      dial,
      previewInvoice
    }
  }
})
</script>

<style lang="scss" scoped>
.tips-sxym {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('~@/assets/images/sxym/bg.png') no-repeat center;
  background-size: cover;
  overflow: hidden;

  .overtime, .unsynchronized, .failed, .wait {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  }

  .unsynchronized {
    .sync-title {
      width: 100%;
    }
    .sync-tips {
      margin-top: 100px;
      font-size: 20px;
      color: #EFD5AB;
    }
  }

  .success {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 9px 7px;
    box-sizing: border-box;
    background-color: #0f0704;

    .tips {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      font-size: 13px;
      color: #EFD5AB;
      z-index: 10;
      text-align: center;
      padding: 5px;
      overflow-y: auto;
    }

    .wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      background: url('~@/assets/images/sxym/detail-bg.png') no-repeat center;
      background-size: cover;
      background-color: #111;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 50px 20px 0;
      color: #111;
    }
    .detail-title {
      width: 100%;
    }

    .detail {
      margin-top: 50px;
      width: 100%;
      font-size: 18px;
      color: #111;
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
          margin-right: 4px;
          color: #EFD5AB;
        }
        .value {
          position: relative;
          font-size: 14px;
          padding-left: 16px;
          padding-bottom: 4px;
          flex: 1;
          text-align: left;
          color: #E0D5C1;

          &::after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            height: 1PX;
            background-color: #D4D5D5;
          }
        }
      }
      .view-invoice {
        width: 111px;
        margin: 30px auto 0;
      }
    }
  }

  .wait {
    font-size: 0;
    text-align: center;
    .wait-title {
      width: 100%;
    }
    .wait-tips {
      margin-top: 100px;
      .zh-cn {
        width: 80%;
      }
      .en {
        margin-top: 30px;
        width: 90%;
      }
    }
  }

  .overtime, .failed {
    font-size: 0;
    text-align: center;
    .fail-title {
      width: 100%;
    }
    .fail-tips {
      margin-top: 100px;
      .zh-cn {
        width: 50%;
      }
      .en {
        margin-top: 30px;
        width: 60%;
      }
    }
  }

  .overtime {
    .fail-tips {
      font-size: 22px;
      color: #EFD5AB;
    }
  }

  .logo {
    width: 215px;
    height: 35px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// .contact-modal {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex-direction: column;
//   width: 267px;
//   height: 328px;
//   background: url('~@/assets/images/taier/modal-bg.png') no-repeat center;
//   background-size: cover;
//   box-sizing: border-box;
//   padding: 40px 20px 30px;

//   .contents {
//     text-align: center;
//     .hotline-title {
//       width: 122px;
//     }

//     .desc {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       font-size: 14px;
//       color: #111;
//       margin-top: 15px;

//       .mark {
//         color: #D59E57;
//       }
//     }
//   }

//   .btns {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;

//    .btn {
//       flex: 1;
//       height: 40px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       font-size: 21px;
//       font-weight: 600;
//       color: #111;

//       &.cancel {
//         background: url('~@/assets/images/taier/plain-btn-bg.png') no-repeat center;
//         background-size: cover;
//         margin-right: 8px;
//       }

//       &.confirm {
//         background: url('~@/assets/images/taier/primary-btn-bg.png') no-repeat center;
//         background-size: cover;
//         color: #D59E57;
//       }
//    }
//   }
// }
</style>