const prefix = `${process.env.VUE_APP_APP_NAME}_`

// 品牌标识
export const BrandKey = `${prefix}brand`

// 加密后的订单号
export const EncryptOrderNo = `${prefix}encryptOrderNo`

// 订单号字段key
export const OrderNoKey = `${prefix}orderNoKey`

// 开票金额
export const InvoiceAmount = `${prefix}invoiceAmount`

// 发票url
export const InvoicePdfUrl = `${prefix}invoicePdfUrl`

// 原始url
export const OrgUrl = `${prefix}orgUrl`

// 完整发票信息
export const InvoiceInfo = `${prefix}fullInvoiceInfo`

// 用户上一次开票成功的表单信息
export const UserSubmittedInfo = `${prefix}userSubmiitedInfo`