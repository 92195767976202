import { Field, CellGroup, Button, Form, Radio, RadioGroup, Toast, Dialog, Popup, ActionSheet, Image } from 'vant'
const components = [
    Field,
    CellGroup,
    Button,
    Form,
    Radio,
    RadioGroup,
    Popup,
    ActionSheet,
    Image,
    Toast,
    Dialog
]
Toast.setDefaultOptions('loading', { forbidClick: true, duration: 0 })
export default {
    install: (app, options) => {
        components.forEach(component => component.install(app, options))
    }
}
