import { Post, Get } from '@/utils/request'
import { _getOrgUrl } from '@/utils/storage'
import { isIOS } from '@/utils/index'

// 查看发票开具状态
export function getInvoiceStatus (data) {
  return Post({
    url: '/invoices/status',
    data
  })
}

// 开具发票
export function applyInvoice (data) {
  return Post({
    url: '/invoices',
    data
  })
}

// 获取调用微信jssdk的签名相关信息
export function getJsapiSignature () {
  let url = window.location.href.split('#')[0]
  if (isIOS()) {
    url = _getOrgUrl()
  }
  return Post({
    url: '/invoices/createJsapiSignature',
    data: {
      url
    }
  })
}

/**
 * 模糊搜索公司名称
 * @param {*} data 
 * @returns 
 */
export function fuzzyQueryCompany (data) {
  return Get({
    url: '/invoices/queryCompany',
    data
  })
}
