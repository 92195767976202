import { render } from "./index.vue?vue&type=template&id=45ba0e50&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=45ba0e50&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-45ba0e50"
/* hot reload */
if (module.hot) {
  script.__hmrId = "45ba0e50"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('45ba0e50', script)) {
    api.reload('45ba0e50', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=45ba0e50&scoped=true", () => {
    api.rerender('45ba0e50', render)
  })

}

script.__file = "src/views/main/taier/index.vue"

export default script