import axios from 'axios'
import { _getRealBrand } from '@/utils/storage'

const service = axios.create({
  timeout: 20000, // request timeout
  baseURL: process.env.VUE_APP_HOST
});

export function Get ({ url, data = {} }) {
  return new Promise((resolve, reject) => {
    service.get(url, { params: data, headers: { brand: _getRealBrand() } })
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

export function Post ({ url, data }) {
  return new Promise((resolve, reject) => {
    service.post(url, data, { headers: { brand: _getRealBrand() } })
      .then(res => resolve(res.data))
      .catch(reject)
  })
}
