<template>
  <div :class="btnClass" @click="onBtnClick">
    <span class="btn-text">其他发票信息(选填)</span>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { _getViewBrand } from '@/utils/storage'
import ClassNames from 'classnames'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const brand = _getViewBrand()
    const btnClass = computed(() => ClassNames(
      'more-info-btn',
      {
        'song-more-info-btn': brand === 'song',
        'beauty-more-info-btn': brand === 'laimeili',
        'sxym-more-info-btn': brand === 'sxym',
        'super-tomato-info-btn': brand ==='super-tomato',
        'mountain-outside-info-btn': brand ==='mountain-outside',
        'is-unfold': props.modelValue
      }
    ))

    const onBtnClick = () => {
      emit('update:modelValue', !props.modelValue)
    }

    return {
      btnClass,
      onBtnClick
    }
  }
})
</script>

<style lang="scss" scoped>
.more-info-btn {
  width: 130px;
  height: 33px;
  font-size: 11px;
  font-weight: normal;
  margin: 15px auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('~@/assets/images/taier/more-info-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  .btn-text {
    margin-right: 10px;
  }

  &::after {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background-image: url('~@/assets/images/taier/arrow-down.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform .3s ease-in-out;
    right: 8px;
  }

  &.is-unfold {
    &::after {
      transform: rotate(0.5turn);
    }
  }

  &.song-more-info-btn {
    border: 2PX solid #060100;
    background-color: #EFF3EE;
    color: #1C393F;
    font-size: 12px;
    border-radius: 14px;
    background-image: none;

    &::after {
      right: 5px;
      background-image: url('~@/assets/images/song/arrow-down.png');
    }
  }

  &.beauty-more-info-btn {
    border: 2PX solid #BB3B29;
    border-radius: 0;
    background-color: #E9C461;
    color: #231916;
    font-size: 12px;
    background-image: none;

    &::after {
      right: 5px;
      background-image: url('~@/assets/images/laimeili/arrow-down.png');
    }
  }
  &.sxym-more-info-btn {
    border: 1PX solid #E0D5C1;
    background: none;
    color: #E0D5C1;

    &::after {
      right: 5px;
      background-image: url('~@/assets/images/sxym/arrow-down.png');
    }
  }
  &.mountain-outside-info-btn {
    width: 184px;
    height: 40px;
    background-image: url('~@/assets/images/mountain-outside/more-info-bg.png');
    background-size: contain;
    margin: 24px auto;

    span {
      display: none;
    }

    &::after {
      width: 14px;
      height: 8px;
      right: 15px;
      background-image: url('~@/assets/images/mountain-outside/arrow-down.png');
    }
  }
}
</style>