<template>
  <div class="laimeili">
    <img class="title" src="@/assets/images/laimeili/title.png" />
    <div class="form-wrapper">
      <div class="container">
        <div class="amount has-bottom-border flex-space-between">
          <div class="label">开具金额:</div>
          <div class="value">{{ invoiceAmount }}</div>
        </div>
        <div class="type flex-space-between">
          <div class="label">发票类型</div>
          <div class="value">电子发票</div>
        </div>
      </div>
      <van-form
        class="invoice-form"
        validate-trigger="onSubmit"
        validate-first
        ref="formRef"
        @submit="onSubmit"
        @failed="onFailed"
        label-width="5em"
        :colon="false"
      >
        <div class="container">
          <div class="sub-title">发票详情</div>
          <van-field name="radio" label="抬头类型">
            <template #input>
              <van-radio-group
                v-model="form.type"
                direction="horizontal"
                @change="resetForm"
              >
                <van-radio shape="square" name="2">
                  个人
                  <template #icon="props">
                    <img
                      class="radio-icon"
                      :src="props.checked ? activeRadioIcon : inactiveRadioIcon"
                    />
                  </template>
                </van-radio>
                <van-radio shape="square" name="1">
                  单位
                  <template #icon="props">
                    <img
                      class="radio-icon"
                      :src="props.checked ? activeRadioIcon : inactiveRadioIcon"
                    />
                  </template>
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            label="发票抬头"
            v-model="form.buyerName"
            placeholder="发票抬头（必填）"
            clearable
            :rules="[{ required: true }]"
            class="invoiceTitle"
            ref="companyFieldRef"
          >
            <template #extra v-if="isSupportChooseTitle">
              <span class="chooseTitle" @click="chooseFromWechat">
                获取抬头
              </span>
            </template>
          </van-field>
          <company-list
            v-if="isCompanyTitle"
            :company="form.buyerName"
            :tax-id="form.taxpayerIdentificationNo"
            :binding-el="companyListBindingEl"
            @choose="setChoosedCompany"
          />
          <van-field
            v-if="isCompanyTitle"
            label="公司税号"
            v-model="form.taxpayerIdentificationNo"
            maxlength="20"
            placeholder="纳税人识别码或统一信用代码（必填）"
            clearable
            :rules="[
              { required: true },
              {
                pattern: /^[a-zA-Z0-9]{7,20}$/,
                message: '税号填写不合法',
              },
            ]"
          />
          <van-field
            label="手机号码"
            v-model.trim="form.buyerPhone"
            placeholder="手机号码（选填）"
            clearable
            :rules="[
              { validator: phoneValidator, message: '请输入有效的手机号码' },
            ]"
          />
          <template v-if="isCompanyTitle && showMore">
            <van-field
              label="单位地址"
              v-model.trim="form.buyerAddress"
              type="textarea"
              placeholder="单位地址信息（选填）"
              maxlength="40"
              rows="3"
              show-word-limit
              clearable
            />
            <van-field
              label="开户银行"
              v-model="form.buyerBank"
              placeholder="开户银行名称（选填）"
              maxlength="100"
              clearable
            />
            <van-field
              label="银行账号"
              type="digit"
              v-model="form.buyerBankNo"
              placeholder="银行账号号码（选填）"
              maxlength="100"
              clearable
            />
          </template>
          <div
            :class="showMoreInfoBtnClass"
            v-if="isCompanyTitle"
            @click="showMore = !showMore"
          />
        </div>
        <div class="container">
          <div class="sub-title">接收方式</div>
          <van-field
            label="电子邮箱"
            v-model="form.buyerEmail"
            placeholder="请输入邮箱（必填）"
            :rules="[
              { required: true },
              {
                pattern: /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/,
                message: '请输入有效的邮箱',
              },
            ]"
            clearable
          />
        </div>
      </van-form>
    </div>
    <footer class="submit-wrapper flex-center">
      <div :class="btnClass" @click="handleSubmitBtnClick">提交申请</div>
    </footer>
  </div>
</template>

<script>
import { defineComponent, nextTick, computed, ref } from "vue";
import useInvoiceForm from "@/composables/invoiceForm";
import useWechat from "@/composables/wechat";
import { phoneValidator } from "@/utils";
import companyList from "@/components/companyList.vue";
import ClassNames from "classnames";
import useInvoice from "@/composables/invoice";

export default defineComponent({
  components: {
    companyList,
  },
  setup() {
    const {
      invoiceAmount,
      form,
      activeRadioIcon,
      inactiveRadioIcon,
      isCompanyTitle,
      passVerify,
      resetForm,
    } = useInvoiceForm();
    const { doApplyInvoice } = useInvoice();
    const { isSupportChooseTitle, chooseInvoiceTitle } = useWechat();
    const companyListBindingEl = computed(() =>
      companyFieldRef.value
        ? companyFieldRef.value.$el.querySelector(".van-field__control")
        : void 0
    );
    const btnClass = computed(() =>
      ClassNames("submit-btn flex-center", { disabled: !passVerify.value })
    );
    const showMoreInfoBtnClass = computed(() =>
      ClassNames("show-more-btn", { "is-unfold": showMore.value })
    );
    const formRef = ref(null);
    const companyFieldRef = ref(null);
    const showMore = ref(false);
    const onSubmit = () => doApplyInvoice(form);
    const onFailed = () => {};
    const chooseFromWechat = async () => {
      const chooseRes = await chooseInvoiceTitle();
      if (!chooseRes) return;
      const {
        type,
        buyerName,
        taxpayerIdentificationNo,
        buyerAddress,
        buyerPhone,
        buyerBankNo,
        buyerBank,
      } = chooseRes;
      // 切换抬头类型
      form.type = type;
      nextTick(() => {
        form.buyerName = buyerName;
        if (type === "1") {
          // 公司抬头
          form.taxpayerIdentificationNo = taxpayerIdentificationNo;
          form.buyerAddress = buyerAddress;
          form.buyerPhone = buyerPhone;
          form.buyerBankNo = buyerBankNo;
          form.buyerBank = buyerBank;
          if (buyerAddress || buyerPhone || buyerBankNo || buyerBank)
            showMore.value = true;
        }
      });
    };
    const setChoosedCompany = (company) => {
      form.buyerName = company.name;
      form.taxpayerIdentificationNo = company.taxId;
    };
    const handleSubmitBtnClick = () => formRef.value.submit();

    return {
      invoiceAmount,
      form,
      activeRadioIcon,
      inactiveRadioIcon,
      isCompanyTitle,
      isSupportChooseTitle,
      companyListBindingEl,
      formRef,
      companyFieldRef,
      showMore,
      passVerify,
      btnClass,
      showMoreInfoBtnClass,
      phoneValidator,
      onSubmit,
      onFailed,
      resetForm,
      chooseFromWechat,
      setChoosedCompany,
      handleSubmitBtnClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.laimeili {
  min-height: 100vh;
  padding-top: 40px;
  box-sizing: border-box;
  background-image: url("../../../assets/images/laimeili/bg.png");
  background-repeat: round;
  background-size: cover;
  text-align: center;
  padding-bottom: 180px;

  .title {
    width: 285px;
  }

  .form-wrapper {
    margin: 0 10px;
    margin-top: 40px;
    text-align: left;

    .invoice-form {
      .van-cell {
        padding: 10px 0;

        ::v-deep(.van-field__label) {
          color: #000;
        }

        &::after {
          border-bottom-color: #d4d4d4;
          border-bottom-style: dashed;
          left: 0;
          right: 0;
          transform: none;
        }

        &:last-of-type {
          &::before {
            content: "";
            position: absolute;
            box-sizing: border-box;
            pointer-events: none;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px dashed #d4d4d4;
          }
        }
      }

      .invoiceTitle {
        .chooseTitle {
          padding-left: 5px;
          color: #008347;
          font-size: 14px;
        }
      }

      .van-radio {
        .radio-icon {
          width: 15px;
        }
      }

      .show-more-btn {
        width: 161px;
        height: 22px;
        margin: 30px auto 0;
        background-image: url(~@/assets/images/laimeili/show-more-folded.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &.is-unfold {
          background-image: url(~@/assets/images/laimeili/show-more-unfolded.png);
        }
      }

      .container {
        &:first-of-type {
          &::before,
          &::after {
            z-index: 1;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            border-bottom: 2px dashed #008347;
          }

          &::before {
            top: 0;
          }

          &::after {
            bottom: 0;
          }
        }
      }
    }
    .container {
      background: #fff;
      padding: 24px 30px;
      font-size: 13px;
      position: relative;
      clip-path: polygon(
        15px 0,
        calc(100% - 15px) 0,
        100% 15px,
        100% calc(100% - 15px),
        calc(100% - 15px) 100%,
        15px 100%,
        0 calc(100% - 15px),
        0 15px
      );

      .has-bottom-border {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          border-bottom: 1px dashed #d4d4d4;
        }
      }

      .amount {
        font-size: 22px;
        font-weight: 600;
        padding-bottom: 10px;
      }

      .type {
        padding-top: 10px;
      }

      .sub-title {
        font-size: 17px;
        font-weight: 600;
      }
    }
  }

  .submit-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 138px;
    background-image: url(~@/assets/images/laimeili/submit-wrapper-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .submit-btn {
      width: 259px;
      height: 55px;
      background-image: url(~@/assets/images/laimeili/submit-btn-bg.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      font-weight: 600;
      font-size: 24px;
      color: #ffffff;
      filter: none;
      transition: all 0.3s;

      &.disabled {
        pointer-events: none;
        filter: grayscale(1);
      }
    }
  }
}
</style>
