<template>
  <component :is="brand" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { _getViewBrand } from '@/utils/storage'
import taier from './taier/index.vue'
import song from './song/index.vue'
import laimeili from './laimeili/index.vue'
import jiumaojiu from './jiumaojiu/index.vue'
import sxym from './sxym/index.vue'
import superTomato from './super-tomato/index.vue'
import mountainOutside from './mountain-outside/index.vue'

export default defineComponent({
  components: { taier, song, laimeili, jiumaojiu, sxym, mountainOutside, superTomato },
  setup() {
    const brand = _getViewBrand()

    return {
      brand
    }
  },
})
</script>