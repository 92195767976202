<template>
  <van-form
    :class="formClass"
    validate-trigger="onSubmit"
    validate-first
    ref="formRef"
    @submit="onSubmit"
    @failed="onFailed"
    :label-width="labelWidth"
    :colon="showColon"
  >
    <van-cell-group :border="false">
      <van-field label="发票类型" modelValue="电子发票" readonly />
      <van-field label="发票金额" :modelValue="invoiceAmount" readonly />
      <van-field name="radio" label="发票抬头">
        <template #input>
          <van-radio-group
            v-model="form.type"
            direction="horizontal"
            checked-color="#D4A864"
            @change="resetForm"
          >
            <van-radio shape="square" name="2">
              个人
              <template #icon="props">
                <img
                  class="radio-icon"
                  :src="props.checked ? activeRadioIcon : inactiveRadioIcon"
                />
              </template>
            </van-radio>
            <van-radio shape="square" name="1">
              单位
              <template #icon="props">
                <img
                  class="radio-icon"
                  :src="props.checked ? activeRadioIcon : inactiveRadioIcon"
                />
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        :label="isCompanyTitle ? '公司抬头' : '发票名称'"
        v-model="form.buyerName"
        :placeholder="isCompanyTitle ? '单位名称（必填）' : '姓名（必填）'"
        clearable
        :rules="[{ required: true }]"
        class="invoiceTitle"
        ref="companyFieldRef"
      >
        <template #extra v-if="isSupportChooseTitle">
          <span class="chooseTitle" @click="chooseFromWechat">
            选择抬头
          </span>
        </template>
      </van-field>
      <company-list
        v-if="isCompanyTitle"
        :company="form.buyerName"
        :tax-id="form.taxpayerIdentificationNo"
        :binding-el="companyListBindingEl"
        @choose="setChoosedCompany"
      />
      <van-field
        v-if="isCompanyTitle"
        label="识别码"
        v-model="form.taxpayerIdentificationNo"
        maxlength="20"
        placeholder="纳税人识别码或统一信用代码（必填）"
        clearable
        :rules="[
          { required: true },
          {
            pattern:
              /^[a-zA-Z0-9]{7,20}$/,
            message: '税号填写不合法',
          },
        ]"
      />
      <van-field
        label="邮箱地址"
        v-model="form.buyerEmail"
        placeholder="请输入邮箱（必填）"
        :rules="[
          { required: true },
          {
            pattern:
              /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/,
            message: '请输入有效的邮箱',
          },
        ]"
        clearable
      />
      <template v-if="isCompanyTitle && showMore">
        <van-field
          label="单位地址"
          v-model.trim="form.buyerAddress"
          type="textarea"
          placeholder="单位地址信息（选填）"
          maxlength="40"
          rows="3"
          show-word-limit
          clearable
        />
        <van-field
          label="电话号码"
          v-model.trim="form.buyerPhone"
          placeholder="电话号码（选填）"
          clearable
          :rules="[
            { validator: phoneValidator, message: '请输入有效的电话号码' }
          ]"
        />
        <van-field
          label="开户银行"
          v-model="form.buyerBank"
          placeholder="开户银行名称（选填）"
          maxlength="100"
          clearable
        />
        <van-field
          label="银行账号"
          type="digit"
          v-model="form.buyerBankNo"
          placeholder="银行账号号码（选填）"
          maxlength="100"
          clearable
        />
      </template>
    </van-cell-group>
    <slot v-if="isCompanyTitle" name="more" />
  </van-form>
</template>

<script>
import { defineComponent, computed, watch, ref, nextTick } from 'vue'
import ClassNames from 'classnames'
import useInvoiceForm from '@/composables/invoiceForm'
import useInvoice from '@/composables/invoice'
import useWechat from '@/composables/wechat'
import companyList from './companyList.vue'

export default defineComponent({
  props: {
    showMore: {
      type: Boolean,
      default: false
    },
    showColon: {
      type: Boolean,
      default: true
    },
    labelWidth: {
      type: String,
      default: '5em'
    }
  },
  components: {
    companyList
  },
  emits: ['validate', 'update:showMore'],
  setup(props, { emit }) {
    const {
      form,
      invoiceAmount,
      activeRadioIcon,
      inactiveRadioIcon,
      isCompanyTitle,
      passVerify,
      resetForm
    } = useInvoiceForm()
    const { doApplyInvoice } = useInvoice()
    const { isSupportChooseTitle, chooseInvoiceTitle } = useWechat()
    const formRef = ref(null)
    const companyFieldRef = ref(null)
    const formClass = computed(() => ClassNames(
      'main-form',
      {
        'has-expand': isCompanyTitle.value
      }
    ))
    const companyListBindingEl = computed(() => companyFieldRef.value ? companyFieldRef.value.$el.querySelector('.van-field__control') : void 0)

    watch(passVerify, val => emit('validate', val))

    const submitForm = () => formRef.value.submit()

    const onFailed = (err) =>{
      console.log('onFailed',err)
    }
    
    const onSubmit = () => doApplyInvoice(form)

    const chooseFromWechat = async () => {
      const chooseRes = await chooseInvoiceTitle()
      if (!chooseRes) return
      const {
        type,
        buyerName,
        taxpayerIdentificationNo,
        buyerAddress,
        buyerPhone,
        buyerBankNo,
        buyerBank
      } = chooseRes
      // 切换抬头类型
      form.type = type
      nextTick(() => {
        form.buyerName = buyerName
        if (type === '1') {
          // 公司抬头
          form.taxpayerIdentificationNo = taxpayerIdentificationNo
          form.buyerAddress = buyerAddress
          form.buyerPhone = buyerPhone
          form.buyerBankNo = buyerBankNo
          form.buyerBank = buyerBank
          if (buyerAddress || buyerPhone || buyerBankNo || buyerBank) emit('update:showMore', true)
        }
      })
    }

    const phoneValidator = (val) => {
      if (val === "") return true
      if ((/^1[3456789]\d{9}$/.test(val)) ) {
        return true
      } else if ((/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/).test(val)) {
        return true
      } else {
        return false
      }
    }

    const setChoosedCompany = (company) => {
      form.buyerName = company.name
      form.taxpayerIdentificationNo = company.taxId
    }

    return {
      form,
      formRef,
      invoiceAmount,
      activeRadioIcon,
      inactiveRadioIcon,
      isCompanyTitle,
      isSupportChooseTitle,
      formClass,
      companyFieldRef,
      companyListBindingEl,
      onSubmit,
      resetForm,
      doApplyInvoice,
      chooseFromWechat,
      submitForm,
      phoneValidator,
      onFailed,
      setChoosedCompany
    }
  }
})
</script>

<style lang="scss" scoped>
.main-form {
  // overflow: hidden;
  .van-cell {
    padding: 15px 16px;
  }
  &.has-expand {
    .van-cell:last-child::after {
      display: block;
    }
  }
}
</style>