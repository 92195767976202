<template>
  <div :class="btnClass" @click="handleBtnClick">
    <span>提交申请</span>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ClassNames from 'classnames'
import { _getViewBrand } from '@/utils/storage'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: true
    },
    isFixed: {
      type: Boolean,
      default: true
    }
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const brand = computed(() => _getViewBrand())
    const btnClass = computed(() => ClassNames(
      'submitBtn',
      {
        'is-fixed': props.isFixed,
        'btn-taier': brand.value === 'taier',
        'btn-song': brand.value === 'song',
        'btn-beauty': brand.value === 'laimeili',
        'btn-sxym': brand.value === 'sxym',
        'btn-super-tomato': brand.value ==='super-tomato',
        'btn-mountain-outside': brand.value ==='mountain-outside',
        'active': !props.disabled
      }
    ))

    const handleBtnClick = () => emit('submit')

    return {
      btnClass,
      handleBtnClick
    }
  }
})
</script>

<style lang="scss" scoped>
.submitBtn {
  transition: all .3s;
  pointer-events: none;
  margin: 30px auto 0;

  &.is-fixed {
    z-index: 10;
    position: fixed;
    bottom: 37px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    margin: 0;
  }

  &.active {
    pointer-events: initial;
  }
  &.btn-taier {
    width: 307px;
    height: 40px;
    line-height: 40px;
    background-image: url('../assets/images/taier/btn-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    color: #111;
    font-size: 18px;
    font-weight: 600;
    filter: grayscale(1);
    &.active {
      filter: none;
    }
  }
  &.btn-song {
    width: 343px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    box-sizing: border-box;
    background: #D2D7D9;
    border-radius: 13px;
    border: 3PX solid #D2D7D9;
    color: #fff;
    &.active {
      color: #1C393F;
      border-color: #1C393F;
      background-color: #fff;
    }
  }
  &.btn-beauty {
    width: 343px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 2px;
    border: 3PX solid #B0B0B0;
    background-color: #B0B0B0;
    color: #FFFFFF;
    font-weight: 600;
    &.active {
      color: #231916;
      border-color: #BB3B29;
      background-color: #E9C461;
    }
  }
  &.btn-sxym {
    width: 240px;
    height: 47px;
    background-image: url('../assets/images/sxym/btn-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    filter: grayscale(1);
    &.active {
      filter: none;
    }
    span {
      display: none;
    }
  }
  &.btn-mountain-outside {
    width: 251px;
    height: 53px;
    background-image: url('../assets/images/mountain-outside/submit-btn.png');
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    filter: grayscale(1);
    bottom: 80px;
    &.active {
      filter: none;
    }
    span {
      display: none;
    }
  }
}
</style>