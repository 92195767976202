import { Dialog, Toast } from 'vant'
import { filterEmptyProp } from '@/utils'
import { applyInvoice } from '@/api'
import { _getOrderNo, _setInvoicePdfUrl, _getOrderNoKey, _setUserSubmittedInfo } from '@/utils/storage'
import router from '@/router/index'

export default function () {
  const doApplyInvoice = form => {
    Dialog.confirm({
      title: '提示',
      message: '请您确认开票信息无误',
      cancelButtonText: '取消',
      confirmButtonText: '确认提交',
      confirmButtonColor: '#1989fa',
      cancelButtonColor: '#ee0a24'
    }).then(() => {
      const params = filterEmptyProp(form)
      params.type = params.type === '1' ? '单位' : '个人'
      params.buyerAddress && (params.buyerAddress = params.buyerAddress.replace(/\s*/g, ''))
      const loading = Toast.loading('提交中')
      applyInvoice({
        channel: 'H5',
        orderType: '餐饮',
        [_getOrderNoKey()]: _getOrderNo(),
        headDetail: params
      }).then(res => {
        loading.clear()
        if (res.code !== 0) {
          const msg = res.msg || '开票失败，请联系服务员'
          Toast(msg)
          return
        }
        const { currentStatus, pdfUrl } = res.data
        // 已开具好发票
        if (currentStatus === 'SUCC') {
          // 存储发票pdf文件地址
          _setInvoicePdfUrl(pdfUrl)
          Dialog.alert({
            title: '已成功开具发票',
            confirmButtonColor: '#1989fa'
          }).then(() => {
            // 跳转至成功页
            router.replace({
              path: '/tips/success'
            })
          })
        } else { // 未开具好发票
          Dialog.alert({
            title: '申请成功',
            message: '您已申请成功，将在7个工作日内完成开票。',
            confirmButtonColor: '#1989fa'
          }).then(() => {
            // 跳转至等待页
            router.replace({
              path: '/tips/wait'
            })
          })
        }
        _setUserSubmittedInfo(params)
      }).catch(() => loading.clear())
    }).catch(() => {})
  }

  return {
    doApplyInvoice
  }
}