import { BrandKey, EncryptOrderNo, InvoiceAmount, InvoicePdfUrl, OrgUrl, OrderNoKey, InvoiceInfo, UserSubmittedInfo } from '../config/keys'

export const _setBrand = brand => {
  sessionStorage.setItem(BrandKey, brand)
}

export const _getViewBrand = () => {
  const brand = sessionStorage.getItem(BrandKey)
  // return brand === 'sxym' ? 'taier' : brand // 赏鲜悦目皮肤上线前使用太二皮肤临时替代
  return brand
}

export const _getRealBrand = () => sessionStorage.getItem(BrandKey)

export const _setOrderNo = orderNo => {
  sessionStorage.setItem(EncryptOrderNo, orderNo)
}

export const _getOrderNo = () => {
  return sessionStorage.getItem(EncryptOrderNo)
}

export const _setOrderNokey = key => {
  sessionStorage.setItem(OrderNoKey, key)
}

export const _getOrderNoKey = () => {
  return sessionStorage.getItem(OrderNoKey)
}

export const _setInvoiceAmount = amount => {
  sessionStorage.setItem(InvoiceAmount, amount)
}

export const _getInvoiceAmount = () => {
  return sessionStorage.getItem(InvoiceAmount)
}

export const _setInvoicePdfUrl = url => {
  sessionStorage.setItem(InvoicePdfUrl, url)
}

export const _getInvoicePdfUrl = () => {
  return sessionStorage.getItem(InvoicePdfUrl)
}

export const _setOrgUrl = url => {
  sessionStorage.setItem(OrgUrl, url)
}

export const _getOrgUrl = () => {
  return sessionStorage.getItem(OrgUrl)
}

export const _setFullInvoiceInfo = info => {
  sessionStorage.setItem(InvoiceInfo, JSON.stringify(info))
}

export const _getFullInvoiceInfo = () => {
  return JSON.parse(sessionStorage.getItem(InvoiceInfo))
}

export const _setUserSubmittedInfo = info => localStorage.setItem(`${UserSubmittedInfo}_${_getRealBrand()}`, JSON.stringify(info))

export const _getUserSubmittedInfo = () => JSON.parse(localStorage.getItem(`${UserSubmittedInfo}_${_getRealBrand()}`))