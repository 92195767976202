<template>
  <div class="tips-super-tomato">
    <img v-if="tipsType === 'overtime'" class="overtime" src="@/assets/images/super-tomato/fail.png" />
    <img
      v-if="tipsType === 'unsynchronized'"
      class="unsynchronized"
      src="@/assets/images/super-tomato/unsynchronized.png"
    />
    <img
      v-if="tipsType === 'wait'"
      class="wait"
      src="@/assets/images/super-tomato/wait.png"
    />
    <div v-if="tipsType === 'success'" class="success">
      <div class="top-tips fixed-top">
        电子发票与纸质发票具有同等法律效力，可支持报销入账
      </div>
      <div class="wrapper">
        <div class="header">
          <img
            class="title-img"
            src="@/assets/images/super-tomato/invoice-detail-title.png"
          />
        </div>
        <div class="detail">
          <div class="row">
            <div class="label">发票类型</div>
            <div class="value">电子发票</div>
          </div>
          <div class="row">
            <div class="label">开票状态</div>
            <div class="value">
              {{ detail.find((v) => v.field === "currentStatus").value }}
            </div>
          </div>
          <div class="row">
            <div class="label">发票金额</div>
            <div class="value">
              {{ detail.find((v) => v.field === "totalPrice").value }}
            </div>
          </div>
          <div
            class="row"
            v-for="row in detail.filter((v, idx) => idx > 2)"
            :key="row.field"
          >
            <div class="label">{{ row.label }}</div>
            <div class="value">{{ row.value }}</div>
          </div>
        </div>
        <div class="cut-off">
          <div class="item"></div>
          <div class="item"></div>
        </div>
        <div class="btn-wrapper">
          <div class="submit-btn" @click="isShowPreviewInvoiceSheet = true">查看发票</div>
        </div>
      </div>
      <download-invoice-file v-model="isShowPreviewInvoiceSheet" :supportDownloadMethods="canDownloadFileMethodFields" />
    </div>
    <div v-if="tipsType === 'failed'" class="failed">
      <img class="fail-tips" src="@/assets/images/super-tomato/fail.png" />
      <!-- <div class="btn contact-service" @click="dial">联系客服</div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import useInvoiceDetail from "@/composables/useInvoiceDetail";
import downloadInvoiceFile from '@/components/downloadInvoiceFile.vue';

export default defineComponent({
  props: {
    tipsType: {
      type: String,
    },
  },
  components: {
    downloadInvoiceFile
  },
  setup(props) {
    const { detail, canDownloadFileMethodFields } = useInvoiceDetail(props);

    const isShowPreviewInvoiceSheet = ref(false)

    const dial = () => (window.location.href = "tel:4006230089");

    return {
      detail,
      isShowPreviewInvoiceSheet,
      canDownloadFileMethodFields,
      dial
    };
  },
});
</script>

<style lang="scss" scoped>
.tips-super-tomato {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .unsynchronized,
  .overtime,
  .wait {
    width: 70%;
    object-fit: contain;
  }

  .success {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    overflow: auto;
    background-color: #fbdac8;
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 600;
    .top-tips {
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      font-size: 13px;
      line-height: 40px;
      text-align: center;
      background-color: #f1f1f1;
      color: #5d5d5d;
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: hidden;
      padding: 0 5px;
      z-index: 9;
    }
    .wrapper {
      border-radius: 10px;
      margin: 50px 16px 20px;
      font-size: 16px;
      overflow: hidden;

      .header,
      .detail {
        background-color: #fff;
      }
      .header {
        font-size: 0;
        text-align: center;
        padding-top: 30px;

        .title-img {
          width: 120px;
        }
      }

      .detail {
        padding: 20px 16px 22px;
        .title {
          color: #e43019;
          font-size: 18px;
          margin-bottom: 4px;
        }
        .row {
          justify-content: flex-start;
          .value {
            font-size: 14px;
          }
        }
      }

      .row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 0;
        position: relative;
        color: #393939;
        .label {
          margin-right: 20px;
          font-weight: 600;
          flex-shrink: 0;
        }
        .value {
          &.is-focus {
            color: #e43019;
          }
        }
      }

      .cut-off {
        padding: 0 8px;
        position: relative;
        .item {
          height: 8px;
          background-color: #fff;
          position: relative;
          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
          }
          &::before {
            left: -8px;
            background: radial-gradient(
              circle 8px at 0 100%,
              transparent 99.99%,
              #fff 50%
            );
          }

          &::after {
            right: -8px;
            background: radial-gradient(
              circle 8px at 100% 100%,
              transparent 99.99%,
              #fff 50%
            );
          }

          &:last-child {
            &::before {
              background: radial-gradient(
                circle 8px at 0 0,
                transparent 99.99%,
                #fff 50%
              );
            }
            &::after {
              background: radial-gradient(
                circle 8px at 100% 0,
                transparent 99.99%,
                #fff 50%
              );
            }
          }
        }
        &::after {
          content: "";
          position: absolute;
          left: 8px;
          right: 8px;
          border-bottom: 2px dashed #e43019;
          top: 50%;
        }
      }
      .btn-wrapper {
        padding: 30px 16px;
        box-sizing: border-box;
        background-color: #fff;
        .submit-btn {
          padding: 6px 50px;
          color: #fff;
          background-color: #e43019;
          border-radius: 20px;
          text-align: center;
          font-size: 21px;
          width: fit-content;
          margin: 0 auto;
        }
      }
    }
  }
  .failed {
    display: flex;
    width: 100%;
    justify-content: center;
    .fail-tips {
      width: 70%;
    }
  }

  .contact-service {
    position: absolute;
    bottom: 35px;
  }

  .btn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 343px;
    height: 40px;
    box-sizing: border-box;
    font-size: 21px;
    color: #fff;
    background-color: #e43019;
    border-radius: 20px;
    font-weight: 600;
  }
}
</style>
