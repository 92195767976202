<template>
  <div class="jmj">
    <div class="tips fixed-top">
      电子发票与纸质发票具有相同等法律效力，可支持报销入账
    </div>
    <div class="form-wrapper">
      <div class="header">
        <img class="title-img" src="@/assets/images/jiumaojiu/title.png" />
      </div>
      <div class="amount has-dividing-strip">
        <div class="label">发票金额</div>
        <div class="value">{{ invoiceAmount }}</div>
      </div>
      <div class="invoice-detail">
        <van-form
          class="invoice-form"
          validate-trigger="onSubmit"
          validate-first
          ref="formRef"
          @submit="onSubmit"
          @failed="onFailed"
          label-width="5em"
          :colon="false"
        >
          <van-cell-group
            class="base-info-group has-dividing-strip"
            :border="false"
          >
            <div class="title">发票详情</div>
            <van-field name="radio" label="抬头类型">
              <template #input>
                <van-radio-group
                  v-model="form.type"
                  direction="horizontal"
                  @change="resetForm"
                >
                  <van-radio shape="square" name="2">
                    个人
                    <template #icon="props">
                      <img
                        class="radio-icon"
                        :src="
                          props.checked ? activeRadioIcon : inactiveRadioIcon
                        "
                      />
                    </template>
                  </van-radio>
                  <van-radio shape="square" name="1">
                    单位
                    <template #icon="props">
                      <img
                        class="radio-icon"
                        :src="
                          props.checked ? activeRadioIcon : inactiveRadioIcon
                        "
                      />
                    </template>
                  </van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              label="发票抬头"
              v-model="form.buyerName"
              placeholder="发票抬头（必填）"
              clearable
              :rules="[{ required: true }]"
              class="invoiceTitle"
              ref="companyFieldRef"
            >
              <template #extra v-if="isSupportChooseTitle">
                <span class="chooseTitle" @click="chooseFromWechat">
                  选择抬头
                </span>
              </template>
            </van-field>
            <company-list
              v-if="isCompanyTitle"
              :company="form.buyerName"
              :tax-id="form.taxpayerIdentificationNo"
              :binding-el="companyListBindingEl"
              @choose="setChoosedCompany"
            />
            <van-field
              v-if="isCompanyTitle"
              label="公司税号"
              v-model="form.taxpayerIdentificationNo"
              maxlength="20"
              placeholder="纳税人识别码或统一信用代码（必填）"
              clearable
              :rules="[
                { required: true },
                {
                  pattern: /^[a-zA-Z0-9]{7,20}$/,
                  message: '税号填写不合法',
                },
              ]"
            />
            <template v-if="isCompanyTitle && showMore">
              <van-field
                label="单位地址"
                v-model.trim="form.buyerAddress"
                type="textarea"
                placeholder="单位地址信息（选填）"
                maxlength="40"
                rows="3"
                show-word-limit
                clearable
              />
              <van-field
                label="开户银行"
                v-model="form.buyerBank"
                placeholder="开户银行名称（选填）"
                maxlength="100"
                clearable
              />
              <van-field
                label="银行账号"
                type="digit"
                v-model="form.buyerBankNo"
                placeholder="银行账号号码（选填）"
                maxlength="100"
                clearable
              />
            </template>
            <div
              class="show-more-btn"
              v-if="isCompanyTitle"
              @click="showMore = !showMore"
            >
              其他发票信息
              <img
                :class="arrowImgClass"
                src="@/assets/images/jiumaojiu/arrow-down.png"
              />
            </div>
          </van-cell-group>
          <van-cell-group class="accept-info-group" :border="false">
            <div class="title">接收方式</div>
            <van-field
              label="电子邮箱"
              v-model="form.buyerEmail"
              placeholder="请输入邮箱（必填）"
              :rules="[
                { required: true },
                {
                  pattern: /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/,
                  message: '请输入有效的邮箱',
                },
              ]"
              clearable
            />
            <van-field
              label="手机号码"
              v-model.trim="form.buyerPhone"
              placeholder="手机号码（选填）"
              clearable
              :rules="[
                {
                  validator: phoneValidator,
                  message: '请输入有效的手机号码',
                },
              ]"
            />
          </van-cell-group>
          <div class="cut-off">
            <div class="item"></div>
            <div class="item"></div>
          </div>
          <div class="btn-wrapper" >
            <div :class="btnClass" @click="handleSubmitBtnClick">提交申请</div>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, computed, ref } from "vue";
import useInvoiceForm from "@/composables/invoiceForm";
import useWechat from "@/composables/wechat";
import { phoneValidator } from "@/utils";
import companyList from "@/components/companyList.vue";
import ClassNames from "classnames";
import useInvoice from "@/composables/invoice";

export default defineComponent({
  components: {
    companyList,
  },
  setup() {
    const {
      invoiceAmount,
      form,
      activeRadioIcon,
      inactiveRadioIcon,
      isCompanyTitle,
      passVerify,
      resetForm,
    } = useInvoiceForm();
    const { doApplyInvoice } = useInvoice();
    const { isSupportChooseTitle, chooseInvoiceTitle } = useWechat();
    const companyListBindingEl = computed(() =>
      companyFieldRef.value
        ? companyFieldRef.value.$el.querySelector(".van-field__control")
        : void 0
    );
    const btnClass = computed(() =>
      ClassNames("submit-btn", { disabled: !passVerify.value })
    );
    const arrowImgClass = computed(() =>
      ClassNames("arrow-img", { "is-rotate": showMore.value })
    );
    const formRef = ref(null);
    const companyFieldRef = ref(null);
    const showMore = ref(false);
    const onSubmit = () => doApplyInvoice(form);
    const onFailed = () => {};
    const chooseFromWechat = async () => {
      const chooseRes = await chooseInvoiceTitle();
      if (!chooseRes) return;
      const {
        type,
        buyerName,
        taxpayerIdentificationNo,
        buyerAddress,
        buyerPhone,
        buyerBankNo,
        buyerBank,
      } = chooseRes;
      // 切换抬头类型
      form.type = type;
      nextTick(() => {
        form.buyerName = buyerName;
        if (type === "1") {
          // 公司抬头
          form.taxpayerIdentificationNo = taxpayerIdentificationNo;
          form.buyerAddress = buyerAddress;
          form.buyerPhone = buyerPhone;
          form.buyerBankNo = buyerBankNo;
          form.buyerBank = buyerBank;
          if (buyerAddress || buyerPhone || buyerBankNo || buyerBank)
            showMore.value = true;
        }
      });
    };
    const setChoosedCompany = (company) => {
      form.buyerName = company.name;
      form.taxpayerIdentificationNo = company.taxId;
    };
    const handleSubmitBtnClick = () => formRef.value.submit();

    return {
      invoiceAmount,
      form,
      activeRadioIcon,
      inactiveRadioIcon,
      isCompanyTitle,
      isSupportChooseTitle,
      companyListBindingEl,
      formRef,
      companyFieldRef,
      showMore,
      passVerify,
      btnClass,
      arrowImgClass,
      phoneValidator,
      onSubmit,
      onFailed,
      resetForm,
      chooseFromWechat,
      setChoosedCompany,
      handleSubmitBtnClick,
    };
  },
});
</script>

<style lang="less" scoped>
.jmj {
  height: 100vh;
  overflow: auto;
  position: relative;
  background-image: url(~@/assets/images/jiumaojiu/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 16px;
  font-weight: 600;
  color: #393939;

  .tips {
    background-color: #393939;
    color: #fee3a6;
    font-size: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 10px;
    overflow-y: auto;
    z-index: 9;
  }

  .form-wrapper {
    border-radius: 10px;
    margin: 60px 16px 20px;
    overflow: hidden;
    // background-color: #fff;

    .header {
      font-size: 0;
      text-align: center;
      padding-top: 30px;
      background-color: #fff;

      .title-img {
        width: 230px;
      }
    }

    .amount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 35px 16px 25px;
      background-color: #fff;
    }

    .invoice-detail {
      .title {
        color: #e43019;
        font-size: 18px;
      }

      .invoice-form {
        .van-cell-group {
          background: none;
          .van-cell {
            padding-left: 2px;
            padding-right: 0;
            background: none;
            ::v-deep(.van-field__label) {
              color: #393939;
            }
            &::after {
              display: none;
            }
          }
        }
        .invoiceTitle {
          .chooseTitle {
            padding-left: 5px;
            color: #e43019;
            font-size: 14px;
          }
        }

        .van-radio {
          .radio-icon {
            width: 18px;
          }

          &[aria-checked="true"] {
            ::v-deep(.van-radio__label) {
              color: #e43019;
            }
          }
        }

        .base-info-group {
          padding: 20px 16px 30px;
          background-color: #fff;
          .show-more-btn {
            margin: 20px auto 0;
            width: 138px;
            justify-content: center;
            display: flex;
            align-items: center;
            padding: 4px 0;
            font-size: 16px;
            background-color: #fee3a6;
            border-radius: 4px;
            .arrow-img {
              width: 14px;
              margin-left: 5px;
              transition: transform .3s;

              &.is-rotate {
                transform: rotate(180deg);
              }
            }
          }
        }

        .accept-info-group {
          padding: 20px 16px 0;
          background-color: #fff;
        }
      }
    }

    .cut-off {
      padding: 0 8PX;
      position: relative;
      .item {
        height: 8PX;
        background-color: #fff;
        position: relative;
        &::before, &::after {
          content: '';
          position: absolute;
          width: 8PX;
          height: 8PX;
        }
        &::before {
          left: -8PX;
          background: radial-gradient(circle 8PX at 0 100%,  transparent 99.99%, #fff);
        }

        &::after {
          right: -8PX;
          background: radial-gradient(circle 8PX at 100% 100%, transparent 99.99%, #fff);
        }
        
        &:last-child {
          &::before {
            background: radial-gradient(circle 8PX at 0 0,  transparent 99.99%, #fff 50%);
          }
          &::after {
            background: radial-gradient(circle 8PX at 100% 0,  transparent 99.99%, #fff 50%);
          }
        }
      }
      &::after {
        content: '';
        position: absolute;
        left: 8PX;
        right: 8PX;
        border-bottom: 2PX dashed #e43019;
        top: 50%;
      }
    }

    .btn-wrapper {
      padding: 30px 16px;
      box-sizing: border-box;
      background-color: #fff;
      .submit-btn {
        padding: 6px;
        color: #fff;
        background-color: #e43019;
        border-radius: 20px;
        text-align: center;
        font-size: 21px;
        transition: all 0.3s;
        

        &.disabled {
          background-color: #e4e4e4;
          color: #8d8d8d;
        }
      }
    }
  }

  .has-dividing-strip {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 10px;
      background-color: #f1f1f1;
    }
  }
}
</style>
