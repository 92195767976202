import { getInvoiceStatus } from "@/api";
import { encrypt } from "./rsaEncrypt";
import { Toast } from "vant";
import BRANDS from "@/config/brand";
import {
  _setOrderNo,
  _setInvoiceAmount,
  _setInvoicePdfUrl,
  _setBrand,
  _setOrderNokey,
  _setFullInvoiceInfo,
} from "@/utils/storage";

const routeOpt = [
  { data: "未开票", nextRoute: "/main" },
  { data: "超过30天", nextRoute: "/tips/overtime" },
  { data: "待开票", nextRoute: "/tips/wait" },
  { data: "开票中", nextRoute: "/tips/wait" },
  { data: "成功", nextRoute: "/tips/success" },
  { data: "失败", nextRoute: "/tips/failed" },
];

// 获取开票状态工具
export function getInvStatus({ orderNo, info }) {
  return new Promise((resolve) => {
    if (!orderNo && !info) {
      Toast.fail("缺少订单信息");
      return resolve(false);
    }
    const loading = Toast.loading("加载中");
    // 注意：最新的订单号字段为info（已加密），旧的字段为orderNo，此处对旧字段做了兼容
    const encryptOrderNo = info ? info : encrypt(orderNo);
    const orderNoKey = info ? "info" : "orderNo";
    _setOrderNo(encryptOrderNo);
    _setOrderNokey(orderNoKey);
    getInvoiceStatus({
      [orderNoKey]: encryptOrderNo,
    })
      .then((res) => {
        loading.clear();
        // 订单尚未同步完成的情况
        if (res.code === 1 && res.msg === "订单不存在") {
          return resolve("/tips/unsynchronized");
          // return resolve('/main')
        }
        if (res.code !== 0) {
          Toast.fail(res.msg);
          return resolve(false);
        }
        const { data } = res;
        _setFullInvoiceInfo(data);
        _setInvoiceAmount(data.totalPrice);
        data.currentStatus === "成功" && _setInvoicePdfUrl(data.invoicePdfUrl);
        const matchOpt = routeOpt.find((v) => v.data === data.currentStatus);
        const nextRoute = matchOpt ? matchOpt.nextRoute : "";
        resolve(nextRoute);
      })
      .catch(() => Toast.fail("网络请求失败"));
  });
}

// 过滤对象中的空属性
export function filterEmptyProp(obj) {
  if ({}.toString.call(obj) !== "[object Object]") return obj;
  const temObj = Object.assign({}, obj);
  for (const key in temObj) {
    const val = temObj[key];
    if (val === null || typeof val === "undefined" || val === "") {
      delete temObj[key];
    }
  }
  return temObj;
}

// 判断是否IOS环境
export function isIOS() {
  let isIphone = navigator.userAgent.includes("iPhone");
  let isIpad = navigator.userAgent.includes("iPad");
  return isIphone || isIpad;
}

export function isAndroid() {
  return /Android/.test(navigator.userAgent);
}

export function isWeiXin() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("micromessenger") != -1) {
    return true;
  } else {
    return false;
  }
}

/**
 * 设置当前品牌,默认为太二
 * @param {String} brand 品牌
 */
export function setBrand(brand) {
  if (BRANDS.includes(brand)) {
    _setBrand(brand);
  } else {
    _setBrand(BRANDS[0]);
  }
}

/**
 * 防抖动函数
 * @param {Function} fn 待处理函数
 * @param {Number} delay 延迟时间
 */
export const debounce = (fn, delay = 2000) => {
  let timer = null;
  return function() {
    const that = this;
    const args = arguments;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function() {
      fn.call(that, args);
      clearTimeout(timer);
    }, delay);
  };
};

/**
 * 校验移动手机号及座机号
 * @param {*} val
 * @returns
 */
export const phoneValidator = (val) => {
  if (val === "") return true;
  if (/^1[3456789]\d{9}$/.test(val)) {
    return true;
  } else if (/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(val)) {
    return true;
  } else {
    return false;
  }
};

/**
 * 通过a标签的方式下载文件
 * @param {*} path
 */
export const downloadExistingFile = (name, path) => {
  const a = document.createElement("a");
  a.download = name;
  a.href = path;
  a.target = "_blank";
  a.click();
};
