<template>
  <div class="tips-taier">
    <div v-if="tipsType === 'overtime'" class="overtime">
      <img class="fail-title" src="@/assets/images/taier/fail-title.png" />
      <div class="fail-tips">已超过30天，请联系客服开具</div>
      <img class="fail-service" src="@/assets/images/taier/service.png" />
      <div class="contact-service" @click="showHotLine = true">联系客服</div>
    </div>
    <div v-if="tipsType === 'unsynchronized'" class="unsynchronized">
      <img class="sync-title" src="@/assets/images/taier/sync-title.png" />
      <div class="sync-tips">账单同步中，建议3分钟后再次申请</div>
      <img class="sync-rich" src="@/assets/images/taier/rich.png" />
    </div>
    <div v-if="tipsType === 'wait'" class="wait">
      <img class="wait-title" src="@/assets/images/taier/success-title.png" />
      <div class="wait-tips">申请结果将发送到邮箱，请注意查收</div>
      <img class="wait-service" src="@/assets/images/taier/service.png" />
    </div>
    <div v-if="tipsType === 'success'" class="success">
      <div class="wrapper">
        <img class="detail-title" src="@/assets/images/taier/detail-title.png" />
        <div class="detail">
          <div class="row" v-for="row in detail" :key="row.field">
            <div class="label">{{ row.label }}</div>
            <div class="value">{{ row.value }}</div>
          </div>
          <div class="view-invoice" @click="isShowPreviewInvoiceSheet = true">
            <span>查看发票</span>
            <img class="arrow-right" src="@/assets/images/taier/arrow-right.png" />
          </div>
        </div>
        <div class="bottom-tips">电子发票与纸质发票具有同等法律效力，可支持报销入账</div>
      </div>
      <download-invoice-file v-model="isShowPreviewInvoiceSheet" :supportDownloadMethods="canDownloadFileMethodFields" />
    </div>
    <div v-if="tipsType === 'failed'" class="failed">
      <img class="fail-title" src="@/assets/images/taier/fail-title.png" />
      <div class="fail-tips">您的开票申请异常，请联系客服</div>
      <img class="fail-service" src="@/assets/images/taier/service.png" />
      <div class="contact-service" @click="showHotLine = true">联系客服</div>
    </div>
    <van-popup
      v-model:show="showHotLine"
      style="background-color: transparent;"
      teleport="body"
      safe-area-inset-top
      safe-area-inset-bottom
      :close-on-click-overlay="false"
    >
      <div class="contact-modal">
        <div class="contents">
          <img class="hotline-title" src="@/assets/images/taier/hotline-title.png" />
          <div class="desc">
            <span>如有需要请拨打客服热线</span>
            <span class="mark">{{ isSxym ? '17520081161' : '400-6092-822' }}</span>
          </div>
          <div class="desc">
            <span>接线时间: 周一到周日</span>
            <span class="mark">9:30-18:00</span>
          </div>
          <div class="desc">(节假日除外)</div>
        </div>
        <div class="btns">
          <div class="btn cancel" @click="showHotLine = false">取消</div>
          <div class="btn confirm" @click="dial">拨打</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import useInvoiceDetail from '@/composables/useInvoiceDetail'
import { _getRealBrand } from '@/utils/storage'
import downloadInvoiceFile from '@/components/downloadInvoiceFile.vue';

export default defineComponent({
  props: {
    tipsType: {
      type: String
    }
  },
  components: {
    downloadInvoiceFile
  },
  setup(props) {
    const { detail, canDownloadFileMethodFields } = useInvoiceDetail(props)
    const isSxym = _getRealBrand() === 'sxym'

    const showHotLine = ref(false)
    const isShowPreviewInvoiceSheet = ref(false)

    const dial = () => {
      window.location.href = isSxym ? 'tel:17520081161' : 'tel:4006092822'
      showHotLine.value = false
    }

    return {
      detail,
      showHotLine,
      isSxym,
      isShowPreviewInvoiceSheet,
      canDownloadFileMethodFields,
      dial,
    }
  }
})
</script>

<style lang="scss" scoped>
.tips-taier {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('~@/assets/images/taier/tips-bg.png') no-repeat center;
  background-size: cover;
  overflow: hidden;

  .overtime, .unsynchronized, .failed, .wait {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
  }

  .unsynchronized {
    .sync-title {
      width: 320px;
    }
    .sync-tips {
      color: #111;
      font-size: 16px;
      font-weight: 600;
      margin: 30px 0;
    }
    .sync-rich {
      width: 300px;
    }
  }

  .success {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 9px 7px;
    box-sizing: border-box;
    background-color: #111;

    .wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      background: url('~@/assets/images/taier/success-bg.png') no-repeat center;
      background-size: cover;
      background-color: #111;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 40px 20px 0;
      color: #111;
    }
    .detail-title {
      width: 250px;
    }

    .detail {
      margin-top: 50px;
      width: 100%;
      font-size: 18px;
      color: #111;
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
          font-weight: 600;
          margin-right: 4px;
        }
        .value {
          position: relative;
          font-size: 14px;
          padding-left: 16px;
          padding-bottom: 4px;
          flex: 1;
          text-align: left;

          &::after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            height: 1PX;
            background-color: #D4D5D5;
          }
        }
      }
      .view-invoice {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        .arrow-right {
          width: 18px;
          margin-left: 8px;
        }
      }
    }

    .bottom-tips {
      position: absolute;
      bottom: 10px;
      font-size: 12px;
    }
  }

  .wait {
    .wait-title {
      width: 256px;
    }
    .wait-tips {
      color: #111;
      font-size: 16px;
      font-weight: 600;
      margin: 30px 0;
    }
    .wait-service {
      width: 263px;
    }
  }

  .overtime, .failed {
    .fail-title {
      width: 253px;
    }
    .fail-tips {
      margin: 30px 0;
      font-size: 16px;
      color: #111;
      font-weight: 600;
    }
    .fail-service {
      width: 263px;
    }
    .contact-service {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #111;
      font-weight: 600;
      background: url('~@/assets/images/taier/btn-bg.png') no-repeat center;
      background-size: cover;
      width: 307px;
      height: 40px;
    }
  }
}

.contact-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 267px;
  height: 328px;
  background: url('~@/assets/images/taier/modal-bg.png') no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  padding: 40px 20px 30px;

  .contents {
    text-align: center;
    .hotline-title {
      width: 122px;
    }

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #111;
      margin-top: 15px;

      .mark {
        color: #D59E57;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 100%;

   .btn {
      flex: 1;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-weight: 600;
      color: #111;

      &.cancel {
        background: url('~@/assets/images/taier/plain-btn-bg.png') no-repeat center;
        background-size: cover;
        margin-right: 8px;
      }

      &.confirm {
        background: url('~@/assets/images/taier/primary-btn-bg.png') no-repeat center;
        background-size: cover;
        color: #D59E57;
      }
   }
  }
}
</style>