<template>
  <div class="tips-song">
    <template v-if="tipsType === 'overtime'">
      <img
        class="overtime"
        src="@/assets/images/song/overtime.png"
      />
      <div class="btn contact-service" @click="contactService">联系客服</div>
    </template>
    <img
      v-if="tipsType === 'unsynchronized'"
      class="unsynchronized"
      src="@/assets/images/song/unsynchronized.png"
    />
    <img
      v-if="tipsType === 'wait'"
      class="wait"
      src="@/assets/images/song/wait.png"
    />
    <div v-if="tipsType === 'success'" class="success">
      <div class="top-tips">电子发票与纸质发票具有同等法律效力，可支持报销入账</div>
      <div class="wrapper">
        <div class="detail">
          <div class="row" v-for="(row, idx) in detail" :key="row.field">
            <div class="label">{{ row.label }}:</div>
            <div class="value">{{ row.value }}</div>
            <div v-if="idx === 0" class="view-invoice" @click="isShowPreviewInvoiceSheet = true">
              <span>查看发票</span>
              <span class="arrow-right">></span>
            </div>
          </div>
        </div>
      </div>
      <download-invoice-file v-model="isShowPreviewInvoiceSheet" :supportDownloadMethods="canDownloadFileMethodFields" />
    </div>
    <div v-if="tipsType === 'failed'" class="failed">
      <img class="fail-tips" src="@/assets/images/song/fail.png" />
      <div class="btn contact-service" @click="contactService">联系客服</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import useInvoiceDetail from '@/composables/useInvoiceDetail'
import downloadInvoiceFile from '@/components/downloadInvoiceFile.vue';

export default defineComponent({
  props: {
    tipsType: {
      type: String
    }
  },
  components: {
    downloadInvoiceFile
  },
  setup(props) {
    const { detail, canDownloadFileMethodFields } = useInvoiceDetail(props)

    const isShowPreviewInvoiceSheet = ref(false)

    const contactService = () => window.location.href = 'https://html5.jmj1995.com/html5/jump-mp.html?appid=wx1d1005fad8379980&path=member/pages/community/index'

    return {
      detail,
      isShowPreviewInvoiceSheet,
      canDownloadFileMethodFields,
      contactService
    }
  }
})
</script>

<style lang="scss" scoped>
.tips-song {
  min-height: 100vh;
  background-image: url('../../../assets/images/song/tips-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .unsynchronized {
    margin-top: -40px;
    width: 334px;
  }
  .overtime {
    width: 301px;
    margin-top: -40px;
  }
  .wait {
    width: 288px;
    margin-top: -40px;
  }
  .success {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    overflow: hidden;
    background-image: url('~@/assets/images/song/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    .top-tips {
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      background-color: #fff;
      color: #FF4438;
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: hidden;
      padding: 0 5px;
    }
    .wrapper {
      background-color: #1C393F;
      border-radius: 16px;
      border: 3PX solid #FF4438;
      padding: 0 13px;
      margin: 25px 16px 0;
      .detail {
        font-size: 14px;
        .row {
          display: flex;
          align-content: center;
          padding: 15px 0;
          position: relative;
          &::after {
            position: absolute;
            content: '';
            border-bottom: 1PX dashed rgba(239, 243, 238, 0.2);
            left: 0;
            right: 0;
            bottom: 0;
          }
          .label {
            margin-right: 16px;
            color: #FFA53A;
          }
          .value {
            color: #FFFFFF;
          }
          .view-invoice {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: #FFA53A;
            .arrow-right {
              font-size: 18px;
              margin-left: 8px;
              font-weight: bold;
            }
          }
        }
        // 去掉最后一行的伪元素
        :last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .failed {
    .fail-tips {
      width: 256px;
    }
  }

  .contact-service {
    position: absolute;
    bottom: 35px;
  }

  .btn {
    left: 50%;
    transform: translateX(-50%);
    width: 343px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 13px;
    border: 3PX solid #1C393F;
    background-color: #fff;
    color: #1C393F;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>