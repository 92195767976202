<template>
  <div class="song">
    <div class="tips">电子发票与纸质发票具有同等法律效力，可支持报销入账</div>
    <div class="main">
      <invoice-form
        ref="formRef"
        v-model:showMore="showMore"
        @validate="(val) => (canSubmit = val)"
      >
        <template v-slot:more>
          <more-info v-model="showMore" />
        </template>
      </invoice-form>
      <submit-btn
        :disabled="!canSubmit"
        :is-fixed="!showMore"
        @submit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import InvoiceForm from "@/components/invoiceForm.vue";
import MoreInfo from "@/components/moreInfo.vue";
import SubmitBtn from "@/components/submitBtn.vue";

export default defineComponent({
  components: {
    InvoiceForm,
    MoreInfo,
    SubmitBtn,
  },
  setup() {
    const showMore = ref(false);
    const canSubmit = ref(false);
    const formRef = ref(null);
    const onSubmit = () => formRef.value.submitForm();

    return {
      showMore,
      canSubmit,
      formRef,
      onSubmit,
    };
  },
});
</script>

<style lang="scss" scoped>
.song {
  min-height: 100vh;
  padding-top: 40px;
  box-sizing: border-box;
  background-image: url("../../../assets/images/song/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .tips {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    z-index: 9;
    background-color: #fff;
    color: #ff4438;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    padding: 0 5px;
  }
  .main {
    padding: 30px 15px 100px;
    .main-form {
      border-radius: 16px;
      border: 3px solid #ff4438;
      overflow: hidden;
      background-color: #1c393f;
      ::v-deep(.van-cell-group) {
        background-color: #1c393f;
        .van-cell {
          font-weight: 400;
          background-color: #1c393f;
          &::after {
            border: 1px dashed rgba(239, 243, 238, 0.2);
            left: 16px;
            right: 16px;
          }
          .van-field__label {
            color: #ffa53a;
          }
          .van-field__control {
            color: #fff;
            &::placeholder {
              opacity: 0.5;
            }
          }
          .van-radio__label {
            color: #fff;
            opacity: 0.5;
          }
          .van-cell__value {
            .radio-icon {
              width: 18px;
              height: 18px;
            }
          }
          .van-radio__icon--checked {
            & + .van-radio__label {
              opacity: 1;
            }
          }
        }
      }
      ::v-deep(.invoiceTitle) {
        .chooseTitle {
          padding-left: 5px;
          color: #ffa53a;
        }
      }
    }
  }
}
</style>
