import { render } from "./moreInfo.vue?vue&type=template&id=49fba433&scoped=true"
import script from "./moreInfo.vue?vue&type=script&lang=js"
export * from "./moreInfo.vue?vue&type=script&lang=js"

import "./moreInfo.vue?vue&type=style&index=0&id=49fba433&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-49fba433"
/* hot reload */
if (module.hot) {
  script.__hmrId = "49fba433"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('49fba433', script)) {
    api.reload('49fba433', script)
  }
  
  module.hot.accept("./moreInfo.vue?vue&type=template&id=49fba433&scoped=true", () => {
    api.rerender('49fba433', render)
  })

}

script.__file = "src/components/moreInfo.vue"

export default script