import { render } from "./index.vue?vue&type=template&id=8bf55178&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=8bf55178&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-8bf55178"
/* hot reload */
if (module.hot) {
  script.__hmrId = "8bf55178"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8bf55178', script)) {
    api.reload('8bf55178', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=8bf55178&scoped=true", () => {
    api.rerender('8bf55178', render)
  })

}

script.__file = "src/views/main/song/index.vue"

export default script