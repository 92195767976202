export const invoiceFileMethodField = {
  /**
   * png
   */
  pdfUrl: "pdfUrl",
  /**
   * pdf
   */
  pdfUrl2: "pdfUrl2",
  /**
   * ofd
   */
  ofdUrl: "ofdUrl",
  /**
   * xml
   */
  xmlUrl: "xmlUrl"
}

export const fullCanDownloadFileMethodFields = [
  invoiceFileMethodField.pdfUrl,
  invoiceFileMethodField.pdfUrl2,
  invoiceFileMethodField.ofdUrl,
  invoiceFileMethodField.xmlUrl
]

export const canDownloadFileMethodOpts = [
  { name: '查看图片', methodField: invoiceFileMethodField.pdfUrl },
  { name: 'PDF文件', methodField: invoiceFileMethodField.pdfUrl2 },
  { name: 'OFD文件', methodField: invoiceFileMethodField.ofdUrl },
  { name: 'XML文件', methodField: invoiceFileMethodField.xmlUrl }
]