<template>
  <van-action-sheet
    class="preview-sheet"
    v-model:show="isShowPreviewSheet"
    title="查看发票"
  >
    <div class="content img-bg-center">
      <van-image
        class="invoice-img"
        v-if="isShowInvoiceImg"
        width="100%"
        fit="contain"
        :src="invoiceImg"
        @click="previewInvoice"
      />
      <div
        class="download-btn img-bg-center"
        @click="isShowPitchDownloadWaySheet = true"
      />
    </div>
  </van-action-sheet>
  <van-action-sheet
    v-model:show="isShowPitchDownloadWaySheet"
    :actions="downloadActions"
    cancel-text="取消"
    description="发票下载"
    close-on-click-action
    @select="handleSelectMethod"
  />
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { _getInvoicePdfUrl, _getFullInvoiceInfo } from "@/utils/storage";
import { ImagePreview, Toast } from "vant";
import {
  canDownloadFileMethodOpts,
  invoiceFileMethodField,
} from "@/config/business";
import { saveAs } from "file-saver";
import { isAndroid, isWeiXin } from "@/utils";

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    supportDownloadMethods: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isShowPreviewSheet = ref(props.modelValue);
    const isShowPitchDownloadWaySheet = ref(false);
    const invoiceImg = ref(_getInvoicePdfUrl());

    const isShowInvoiceImg = computed(() => invoiceImg.value.includes(".png"));
    const downloadActions = computed(() =>
      canDownloadFileMethodOpts.filter((opt) =>
        props.supportDownloadMethods.includes(opt.methodField)
      )
    );

    const previewInvoice = () => ImagePreview([invoiceImg.value]);

    const handleSelectMethod = ({ methodField }) => {
      const fullInfo = _getFullInvoiceInfo();
      const filePath = fullInfo[methodField];
      if (typeof filePath === "string" && filePath.length > 0) {
        if (methodField === invoiceFileMethodField.pdfUrl) {
          previewInvoice();
        } else {
          /**
           * H5下载文件时需要对安卓进行特殊处理。（通过request header区分请求是否来源于微信）
           * 在安卓微信内打开时返回txt文件，此时微信将弹窗引导用户打开自带浏览器进行下载，在浏览器访问该链接将返回真实格式文件。
           */
          if (isAndroid() && isWeiXin()) {
            let androidFilePath = "";
            switch (methodField) {
              case invoiceFileMethodField.ofdUrl:
                androidFilePath = fullInfo.ofdFormatUrl;
                break;
              case invoiceFileMethodField.xmlUrl:
                androidFilePath = fullInfo.xmlFormatUrl;
                break;
              default:
                androidFilePath = fullInfo.pdfFormatUrl;
                break;
            }
            if (androidFilePath) window.open(androidFilePath, "_blank");
            else Toast.fail("正在支持预览该格式的发票");
          } else
            saveAs(
              filePath,
              `${fullInfo.invoiceNo || ""}${fullInfo.invoiceCode ||
                ""}.${new URL(filePath).pathname.split(".").pop()}`
            );
        }
      }
    };

    watch(
      () => props.modelValue,
      (val) => (isShowPreviewSheet.value = val)
    );

    watch(isShowPreviewSheet, (val) => emit("update:modelValue", val));

    return {
      isShowPreviewSheet,
      invoiceImg,
      isShowInvoiceImg,
      isShowPitchDownloadWaySheet,
      downloadActions,
      previewInvoice,
      handleSelectMethod,
    };
  },
});
</script>

<style lang="scss">
.preview-sheet {
  max-height: none !important;
  height: 80%;

  .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    .invoice-img {
      margin-top: 50px;
    }

    .download-btn {
      position: absolute;
      width: 214px;
      height: 52px;
      left: 50%;
      bottom: 22px;
      transform: translateX(-50%);
      z-index: 1;
      background-size: contain;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 88px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.tips-jiumaojiu {
  .preview-sheet {
    .content {
      background-image: url("../assets/images/jiumaojiu/bg.png");

      .download-btn {
        background-image: none;
        background-color: #fee3a6;
        width: 343px;
        height: 40px;
        border-radius: 19px;

        &::after {
          position: absolute;
          content: "发票下载";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #393939;
          font-size: 18px;
          font-weight: 600;
        }
      }

      &::after {
        display: none;
      }
    }
  }
}

.tips-laimeili {
  .preview-sheet {
    .content {
      background-image: url("../assets/images/laimeili/bg.png");

      .download-btn {
        width: 259px;
        height: 55px;
        background-image: url("../assets/images/laimeili/submit-btn-bg.png");
        bottom: 43px;

        &::after {
          position: absolute;
          content: "发票下载";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 22px;
          font-weight: 600;
        }
      }

      &::after {
        height: 138px;
        background-image: url("../assets/images/laimeili/submit-wrapper-bg.png");
      }
    }
  }
}

.tips-mountain-outside {
  .preview-sheet {
    .content {
      background-image: url("../assets/images/mountain-outside/invoice-download-bg.png");

      .download-btn {
        background-image: url("../assets/images/mountain-outside/invoice-download-btn-bg.png");
      }

      &::after {
        height: 99px;
        background-image: url("../assets/images/mountain-outside/invoice-download-footer-bg.png");
      }
    }
  }
}

.tips-song {
  .preview-sheet {
    .content {
      background-image: url("../assets/images/song/invoice-download-bg.png");

      .download-btn {
        background-image: url("../assets/images/song/invoice-download-btn-bg.png");
      }

      &::after {
        background-image: url("../assets/images/song/invoice-download-footer-bg.png");
      }
    }
  }
}

.tips-super-tomato {
  .preview-sheet {
    .content {
      background-image: none;
      background-color: #fbdac8;

      .download-btn {
        background-image: none;
        background-color: #e23922;
        width: 311px;
        height: 42px;
        border-radius: 6px;

        &::after {
          position: absolute;
          content: "发票下载";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }
      }

      &::after {
        display: none;
      }
    }
  }
}

.tips-taier {
  .preview-sheet {
    .van-action-sheet__content {
      padding: 10px;
      background-color: #111;

      .content {
        background-image: url("../assets/images/taier/success-bg.png");
        padding: 10px;

        .download-btn {
          width: 335px;
          height: 36px;
          background-image: url("../assets/images/taier/invoice-download-btn-bg.png");

          &::after {
            position: absolute;
            content: "发票下载";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #111;
            font-size: 18px;
            font-weight: 600;
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
