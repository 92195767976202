import { reactive, computed, onMounted, nextTick } from 'vue'
import { _getInvoiceAmount, _getViewBrand, _getUserSubmittedInfo } from '@/utils/storage'

const requiredFields = [
  { type: '1', fields: ['buyerName', 'taxpayerIdentificationNo', 'buyerEmail'] },
  { type: '2', fields: ['buyerName', 'buyerEmail'] }
]

export default function () {
  const brand = _getViewBrand()
  const activeRadioIcon = require(`@/assets/images/${brand}/checked.png`)
  const inactiveRadioIcon = require(`@/assets/images/${brand}/unchecked.png`)

  const form = reactive({
    type: '1',
    taxpayerIdentificationNo: '',
    buyerEmail: '',
    buyerAddress: '',
    buyerPhone: '',
    buyerBank: '',
    buyerBankNo: '',
    buyerName: ''
  })

  const isCompanyTitle = computed(() => form.type === '1')

  const passVerify = computed(() => {
    const { type } = form
    const matchOpt = requiredFields.find(v => v.type === type)
    const { fields } = matchOpt
    const fieldsAmount = fields.length
    let counter = 0
    fields.forEach(key => form[key] !== '' && counter++)
    return counter === fieldsAmount
  })

  const invoiceAmount = computed(() => `¥${ _getInvoiceAmount() || 0 }`)

  /**
   * 重置表单数据
   */
  const resetForm = () => {
    for (let key in form) {
      if (key !== 'type') form[key] = ''
    }
  }

  /**
   * 当存在上一次开票成功的信息时自动填充表单
   */
  const setLastSubmittedFormInfo = () => {
    try {
      const submmitedForm = _getUserSubmittedInfo()
      Object.keys(submmitedForm || {}).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
          if (key === 'type') {
            form.type = submmitedForm.type === '单位' ? '1' : '2'
          } else {
            /**
             * 因为修改抬头类型后默认会在当前事件循环清空表单值
             * 所以赋值行为延迟到下一个循环
             */
            nextTick(() => form[key] = submmitedForm[key])
          }
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  onMounted(setLastSubmittedFormInfo)

  return {
    form,
    isCompanyTitle,
    passVerify,
    invoiceAmount,
    activeRadioIcon,
    inactiveRadioIcon,
    resetForm
  }
}