<template>
  <div class="tips-laimeili">
    <template v-if="tipsType === 'wait'">
      <div class="wait-tips flex-center">
        <img class="wait-img" src="@/assets/images/laimeili/wait.png" />
        <div class="tips">申请结果将发到邮箱,请注意查收</div>
      </div>
    </template>
    <template v-else>
      <div class="invoice-detail flex-column">
        <img
          class="title-img"
          src="@/assets/images/laimeili/detail-title.png"
        />
        <div :class="contentClass">
          <div class="container">
            <div class="amount flex-space-between has-bottom-border">
              <div class="label">开票金额:</div>
              <div class="value">¥{{ totalPrice || 0 }}</div>
            </div>
            <div class="type flex-space-between">
              <div class="label">发票类型</div>
              <div class="value">{{ type }}</div>
            </div>
          </div>
          <div class="container">
            <div class="title">发票详情</div>
            <div class="info has-bottom-border">
              <div class="label">抬头名称</div>
              <div class="value">{{ buyerName }}</div>
            </div>
            <div class="info has-bottom-border">
              <div class="label">开票时间</div>
              <div class="value">{{ issuedTime }}</div>
            </div>
          </div>
          <div class="container">
            <div class="title">接收方式</div>
            <div class="info has-bottom-border">
              <div class="label">电子邮箱</div>
              <div class="value">{{ buyerEmail }}</div>
            </div>
          </div>
        </div>
      </div>
      <footer v-if="isShowFooter" class="flex-center">
        <div class="action-btn flex-center" @click="handleActionBtnClick">
          {{ actionBtnText }}
        </div>
      </footer>
      <download-invoice-file v-model="isShowPreviewInvoiceSheet" :supportDownloadMethods="canDownloadFileMethodFields" />
    </template>
    <!-- <template v-if="tipsType === 'overtime'">
      <img class="overtime" src="@/assets/images/laimeili/fail.png" />
      <div class="btn contact-service" @click="dial">联系客服</div>
    </template>
    <img
      v-if="tipsType === 'unsynchronized'"
      class="unsynchronized"
      src="@/assets/images/laimeili/unsynchronized.png"
    />
    <div v-if="tipsType === 'success'" class="success">
      <div class="top-tips">
        电子发票与纸质发票具有同等法律效力，可支持报销入账
      </div>
      <div class="wrapper">
        <div class="detail">
          <div class="row" v-for="(row, idx) in detail" :key="row.field">
            <div class="label">{{ row.label }}:</div>
            <div class="value">{{ row.value }}</div>
            <div v-if="idx === 0" class="view-invoice" @click="previewInvoice">
              <span>查看发票</span>
              <span class="arrow-right">></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tipsType === 'failed'" class="failed">
      <img class="fail-tips" src="@/assets/images/laimeili/fail.png" />
      <div class="btn contact-service" @click="dial">联系客服</div>
    </div> -->
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, ref } from "vue";
import useInvoiceDetail from "@/composables/useInvoiceDetail";
import { _getFullInvoiceInfo } from "@/utils/storage";
import classNames from "classnames";
import downloadInvoiceFile from '@/components/downloadInvoiceFile.vue';

export default defineComponent({
  props: {
    tipsType: {
      type: String,
    },
  },
  components: {
    downloadInvoiceFile
  },
  setup(props) {
    const invoice = reactive({
      totalPrice: "",
      type: "",
      buyerName: "",
      buyerEmail: "",
      issuedTime: "",
    });

    const isShowPreviewInvoiceSheet = ref(false)

    const isInvoiceSucceed = computed(() => props.tipsType === "success");

    const actionBtnText = computed(() =>
      isInvoiceSucceed.value ? "查看发票" : "联系客服"
    );

    const isShowFooter = computed(() => props.tipsType !== "unsynchronized");

    const contentClass = computed(() =>
      classNames("content", {
        "is-succeed": isInvoiceSucceed.value,
        "is-failed": ["failed", "overtime"].includes(props.tipsType),
      })
    );

    const { canDownloadFileMethodFields } = useInvoiceDetail(props);

    const dial = () => (window.location.href = "tel:4006230089");

    const setInvoiceInfo = () => {
      const {
        totalPrice,
        type,
        buyerName,
        buyerEmail,
        issuedTime,
      } = _getFullInvoiceInfo();
      invoice.totalPrice = totalPrice;
      invoice.type = type;
      invoice.buyerName = buyerName;
      invoice.buyerEmail = buyerEmail;
      invoice.issuedTime = issuedTime;
    };

    const handleActionBtnClick = () => {
      if (isInvoiceSucceed.value) {
        isShowPreviewInvoiceSheet.value = true
      } else {
        dial();
      }
    };

    setInvoiceInfo();

    return {
      ...toRefs(invoice),
      isInvoiceSucceed,
      actionBtnText,
      isShowFooter,
      contentClass,
      isShowPreviewInvoiceSheet,
      canDownloadFileMethodFields,
      dial,
      handleActionBtnClick
    };
  },
});
</script>

<style lang="scss" scoped>
.tips-laimeili {
  min-height: 100vh;
  background-image: url("../../../assets/images/laimeili/bg.png");
  background-repeat: round;
  background-size: cover;
  position: relative;
  .wait-tips {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    background-color: #fff;
    .wait-img {
      width: 288px;
    }
    .tips {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 600;
    }
  }
  .invoice-detail {
    padding: 10px 10px 180px;
    .title-img {
      width: 280px;
      height: 77px;
      margin: 20px auto 50px;
    }
    .content {
      width: 100%;
      .container {
        background-color: #fff;
        padding: 24px 30px;
        font-size: 13px;
        position: relative;
        clip-path: polygon(
          15px 0,
          calc(100% - 15px) 0,
          100% 15px,
          100% calc(100% - 15px),
          calc(100% - 15px) 100%,
          15px 100%,
          0 calc(100% - 15px),
          0 15px
        );

        &:nth-of-type(2) {
          &::before,
          &::after {
            z-index: 1;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            border-bottom: 2px dashed #008347;
          }

          &::before {
            top: 0;
          }

          &::after {
            bottom: 0;
          }
        }

        .amount {
          font-size: 26px;
          font-weight: 600;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 12px;
        }

        .info {
          padding-bottom: 10px;
          margin-bottom: 14px;
        }

        .has-bottom-border {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            border-bottom: 1px dashed #d4d4d4;
          }
        }
      }

      &.is-succeed,
      &.is-failed {
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 117px;
          height: 50px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          z-index: 1;
          transform: translate(20px, -60%);
        }
      }

      &.is-succeed {
        &::before {
          background-image: url(~@/assets/images/laimeili/invoice-succeed-tag.png);
        }
      }

      &.is-failed {
        &::before {
          background-image: url(~@/assets/images/laimeili/invoice-failed-tag.png);
        }

        .container {
          &:nth-of-type(2),
          &:last-of-type {
            display: none;
          }
        }
      }
    }
  }

  footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 138px;
    background-image: url(~@/assets/images/laimeili/submit-wrapper-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .action-btn {
      width: 259px;
      height: 55px;
      background-image: url(~@/assets/images/laimeili/submit-btn-bg.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      font-weight: 600;
      font-size: 24px;
      color: #fff;
      filter: none;
      transition: all 0.3s;
    }
  }
}
</style>
