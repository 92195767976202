import { render } from "./index.vue?vue&type=template&id=4f550209&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=4f550209&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4f550209"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f550209"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f550209', script)) {
    api.reload('4f550209', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=4f550209&scoped=true", () => {
    api.rerender('4f550209', render)
  })

}

script.__file = "src/views/tips/sxym/index.vue"

export default script