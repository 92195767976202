<template>
  <div class="tips-mountain-outside">
    <img v-if="tipsType === 'overtime'" class="overtime" src="@/assets/images/mountain-outside/fail.png" />
    <img
      v-if="tipsType === 'unsynchronized'"
      class="unsynchronized"
      src="@/assets/images/mountain-outside/unsynchronized.png"
    />
    <img
      v-if="tipsType === 'wait'"
      class="wait"
      src="@/assets/images/mountain-outside/wait.png"
    />
    <img v-if="tipsType === 'failed'" class="failed" src="@/assets/images/mountain-outside/fail.png" />
    <div v-if="tipsType === 'success'" class="success">
      <div class="wrapper">
        <img class="detail-title" src="@/assets/images/mountain-outside/detail-title.png" />
        <div class="detail">
          <div class="row" v-for="row in detail" :key="row.field">
            <div class="label">{{ row.label }}:</div>
            <div class="value">{{ row.value }}</div>
          </div>
          <div class="btn-view-invoice" @click="isShowPreviewInvoiceSheet = true" />
        </div>
      </div>
      <download-invoice-file v-model="isShowPreviewInvoiceSheet" :supportDownloadMethods="canDownloadFileMethodFields" />
    </div>
    <!-- <van-popup
      v-model:show="showHotLine"
      style="background-color: transparent;"
      teleport="body"
      safe-area-inset-top
      safe-area-inset-bottom
      :close-on-click-overlay="false"
    >
      <div class="contact-modal">
        <div class="contents">
          <img class="hotline-title" src="@/assets/images/taier/hotline-title.png" />
          <div class="desc">
            <span>如有需要请拨打客服热线</span>
            <span class="mark">{{ isSxym ? '17520081161' : '400-6092-822' }}</span>
          </div>
          <div class="desc">
            <span>接线时间: 周一到周日</span>
            <span class="mark">9:30-18:00</span>
          </div>
          <div class="desc">(节假日除外)</div>
        </div>
        <div class="btns">
          <div class="btn cancel" @click="showHotLine = false">取消</div>
          <div class="btn confirm" @click="dial">拨打</div>
        </div>
      </div>
    </van-popup> -->
    <img v-if="tipsType !== 'success'" class="logo" src="@/assets/images/mountain-outside/logo-light.png" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import useInvoiceDetail from '@/composables/useInvoiceDetail'
import { _getRealBrand } from '@/utils/storage'
import downloadInvoiceFile from '@/components/downloadInvoiceFile.vue';

export default defineComponent({
  props: {
    tipsType: {
      type: String
    }
  },
  components: {
    downloadInvoiceFile
  },
  setup(props) {
    const isShowPreviewInvoiceSheet = ref(false)
    const showHotLine = ref(false)

    const { detail, canDownloadFileMethodFields } = useInvoiceDetail(props)
    const isSxym = _getRealBrand() === 'sxym'

    const dial = () => {
      window.location.href = isSxym ? 'tel:17520081161' : 'tel:4006092822'
      showHotLine.value = false
    }

    return {
      detail,
      showHotLine,
      isSxym,
      canDownloadFileMethodFields,
      isShowPreviewInvoiceSheet,
      dial
    }
  }
})
</script>

<style lang="scss" scoped>
.tips-mountain-outside {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: url('~@/assets/images/mountain-outside/tips-bg.png') no-repeat center;
  text-align: center;
  background-size: cover;

  .unsynchronized,
  .overtime,
  .failed,
  .wait {
    width: 75%;
    margin-top: 60px;
  }

  .success {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url('~@/assets/images/mountain-outside/bg.png') no-repeat center;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    padding: 30px 30px 130px;
    box-sizing: border-box;

    .wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #111;
      overflow: auto;
    }
    .detail-title {
      width: 250px;
      margin-top: 28px;
    }

    .detail {
      margin-top: 50px;
      width: 100%;
      font-size: 14px;
      color: #000;
      font-weight: 600;
      .row {
        display: flex;
        align-items: flex-end;
        margin-bottom: 28px;
        .label {
          display: flex;
          align-items: center;
          margin-right: 10px;
          width: 78px;
          height: 26px;
          background: url(~@/assets/images/mountain-outside/form-label-bg.png) left no-repeat;
          background-size: cover;
          color: #fff;
          padding-left: 5px;
          box-sizing: border-box;
        }
        .value {
          position: relative;
          font-size: 15px;
          padding-left: 12px;
          padding-bottom: 4px;
          flex: 1;
          text-align: left;

          &::after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #D4D5D5;
            border-bottom: 1PX dashed #020202;
          }
        }
      }
    }
    .btn-view-invoice {
      width: 243px;
      height: 53px;
      margin: 40px auto 0;
      background-image: url('~@/assets/images/mountain-outside/view-invoice-btn.png');
      background-size: cover;
      background-repeat: no-repeat;
      border: none;
    }
  }

  .wait {
    text-align: center;
  }

  .overtime, .failed {
    .contact-service {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #111;
      font-weight: 600;
      background: url('~@/assets/images/taier/btn-bg.png') no-repeat center;
      background-size: cover;
      width: 307px;
      height: 40px;
    }
  }

  .logo {
    width: 250px;
    height: 65px;
    position: absolute;
    left: 50%;
    bottom: 44px;
    transform: translateX(-50%);
  }
}

// .contact-modal {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex-direction: column;
//   width: 267px;
//   height: 328px;
//   background: url('~@/assets/images/taier/modal-bg.png') no-repeat center;
//   background-size: cover;
//   box-sizing: border-box;
//   padding: 40px 20px 30px;

//   .contents {
//     text-align: center;
//     .hotline-title {
//       width: 122px;
//     }

//     .desc {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       font-size: 14px;
//       color: #111;
//       margin-top: 15px;

//       .mark {
//         color: #D59E57;
//       }
//     }
//   }

//   .btns {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;

//    .btn {
//       flex: 1;
//       height: 40px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       font-size: 21px;
//       font-weight: 600;
//       color: #111;

//       &.cancel {
//         background: url('~@/assets/images/taier/plain-btn-bg.png') no-repeat center;
//         background-size: cover;
//         margin-right: 8px;
//       }

//       &.confirm {
//         background: url('~@/assets/images/taier/primary-btn-bg.png') no-repeat center;
//         background-size: cover;
//         color: #D59E57;
//       }
//    }
//   }
// }
</style>