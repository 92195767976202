import { ref } from 'vue'
import { _chooseInvoiceFromWechat, _initWechatConfig, _checkJsApi } from '@/utils/wechat'
// import { isIOS } from '@/utils'

export default function () {

  const isSupportChooseTitle = ref(false)

  // 选择微信抬头
  const chooseInvoiceTitle = async () => {
    const chooseRes = await _chooseInvoiceFromWechat()
    if (!chooseRes) return false
    const { type, title, taxNumber, companyAddress, telephone, bankAccount, bankName } = JSON.parse(chooseRes)
    return {
      // 微信中0为公司抬头，此应用中1为公司抬头
      type: type === '0' ? '1' : '2',
      buyerName: title,
      taxpayerIdentificationNo: taxNumber,
      buyerAddress: companyAddress,
      buyerPhone: telephone,
      buyerBankNo: bankAccount,
      buyerBank: bankName
    }
  }

  // 初始化微信sdk
  const initWechatSdk = async () => {
    try {
      await _initWechatConfig()
      const checkRes = await _checkJsApi()
      if (checkRes) {
        isSupportChooseTitle.value = true
      }
    } catch(e) {
      console.log(e)
    }
  }

  initWechatSdk()

  return {
    isSupportChooseTitle,
    chooseInvoiceTitle
  }
}