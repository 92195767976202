<template>
  <div class="tips-jiumaojiu">
    <template v-if="tipsType === 'overtime'">
      <img
        class="overtime"
        src="@/assets/images/jiumaojiu/fail.png"
      />
      <!-- <div class="btn contact-service" @click="dial">联系客服</div> -->
    </template>
    <img
      v-if="tipsType === 'unsynchronized'"
      class="unsynchronized"
      src="@/assets/images/jiumaojiu/unsynchronized.png"
    />
    <img
      v-if="tipsType === 'wait'"
      class="wait"
      src="@/assets/images/jiumaojiu/wait.png"
    />
    <div v-if="tipsType === 'success'" class="success">
      <div class="top-tips fixed-top">电子发票与纸质发票具有同等法律效力，可支持报销入账</div>
      <div class="wrapper">
        <div class="header">
          <img class="title-img" src="@/assets/images/jiumaojiu/title.png" />
        </div>
        <div class="status has-dividing-strip">
          <div class="row">
            <div class="label">开票状态</div>
            <div class="value is-focus">{{ detail.find(v => v.field === 'currentStatus').value }}</div>
          </div>
          <div class="row">
            <div class="label">发票金额</div>
            <div class="value">{{ detail.find(v => v.field === 'totalPrice').value }}</div>
          </div>
        </div>
        <div class="detail">
          <div class="title">发票详情</div>
          <div class="row" v-for="row in detail.filter((v, idx) => idx > 2)" :key="row.field">
            <div class="label">{{ row.label }}</div>
            <div class="value">{{ row.value }}</div>
          </div>
        </div>
        <div class="cut-off">
          <div class="item"></div>
          <div class="item"></div>
        </div>
        <div class="btn-wrapper">
          <div class="submit-btn" @click="isShowPreviewInvoiceSheet = true">查看发票</div>
        </div>
      </div>
      <download-invoice-file v-model="isShowPreviewInvoiceSheet" :supportDownloadMethods="canDownloadFileMethodFields" />
    </div>
    <div v-if="tipsType === 'failed'" class="failed">
      <img class="fail-tips" src="@/assets/images/jiumaojiu/fail.png" />
      <!-- <div class="btn contact-service" @click="dial">联系客服</div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import useInvoiceDetail from '@/composables/useInvoiceDetail'
import downloadInvoiceFile from '@/components/downloadInvoiceFile.vue';

export default defineComponent({
  props: {
    tipsType: {
      type: String
    }
  },
  components: {
    downloadInvoiceFile
  },
  setup(props) {
    const { detail, canDownloadFileMethodFields } = useInvoiceDetail(props)

    const isShowPreviewInvoiceSheet = ref(false)

    const dial = () => window.location.href = 'tel:4006230089'

    return {
      detail,
      isShowPreviewInvoiceSheet,
      canDownloadFileMethodFields,
      dial
    }
  }
})
</script>

<style lang="scss" scoped>
.tips-jiumaojiu {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .unsynchronized {
    width: 100%;
  }
  .overtime {
    width: 100%;
  }
  .wait {
    width: 100%;
  }
  .success {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    overflow: auto;
    background: url(~@/assets/images/jiumaojiu/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 600;
    .top-tips {
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      background-color: #393939;
      color: #FEE3A6;
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: hidden;
      padding: 0 5px;
      z-index: 9;
    }
    .wrapper {
      border-radius: 10px;
      margin: 50px 16px 20px;
      font-size: 16px;
      overflow: hidden;

      .header, .status, .detail {
        background-color: #fff;
      }
      .header {
        font-size: 0;
        text-align: center;
        padding-top: 30px;

        .title-img {
          width: 230px;
        }
      }

      .status {
        padding: 30px 16px;
      }

      .detail {
        padding: 20px 16px 22px;
        .title {
          color: #E43019;
          font-size: 18px;
          margin-bottom: 4px;
        }
        .row {
          justify-content: flex-start;
          .value {
            font-size: 14px;
          }
        }
      }

      .row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 0;
        position: relative;
        color: #393939;
        .label {
          margin-right: 20px;
          font-weight: 600;
          flex-shrink: 0;
        }
        .value {
          &.is-focus {
            color: #E43019;
          }
        }
      }

      .cut-off {
        padding: 0 8PX;
        position: relative;
        .item {
          height: 8PX;
          background-color: #fff;
          position: relative;
          &::before, &::after {
            content: '';
            position: absolute;
            width: 8PX;
            height: 8PX;
          }
          &::before {
            left: -8PX;
            background: radial-gradient(circle 8PX at 0 100%,  transparent 99.99%, #fff 50%);
          }

          &::after {
            right: -8PX;
            background: radial-gradient(circle 8PX at 100% 100%,  transparent 99.99%, #fff 50%);
          }
          
          &:last-child {
            &::before {
              background: radial-gradient(circle 8PX at 0 0,  transparent 99.99%, #fff 50%);
            }
            &::after {
              background: radial-gradient(circle 8PX at 100% 0,  transparent 99.99%, #fff 50%);
            }
          }
        }
        &::after {
          content: '';
          position: absolute;
          left: 8PX;
          right: 8PX;
          border-bottom: 2PX dashed #e43019;
          top: 50%;
        }
      }
      .btn-wrapper {
        padding: 30px 16px;
        box-sizing: border-box;
        background-color: #fff;
        .submit-btn {
          padding: 6px;
          color: #fff;
          background-color: #e43019;
          border-radius: 20px;
          text-align: center;
          font-size: 21px;
        }
      }
    }

    .has-dividing-strip {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-color: #f1f1f1;
      }
    }
  }
  .failed {
    display: flex;
    width: 100%;
    .fail-tips {
      width: 100%;
    }
  }

  .contact-service {
    position: absolute;
    bottom: 35px;
  }

  .btn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 343px;
    height: 40px;
    box-sizing: border-box;
    font-size: 21px;
    color: #fff;
    background-color: #E43019;
    border-radius: 20px;
    font-weight: 600;
  }
}
</style>