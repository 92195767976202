<template>
  <div class="company-list-wrapper">
    <div :class="listClassName" ref="companyListEl">
      <div class="company" v-for="company in companyList" :key="company.taxId" @click="onCompanyClick(company)" v-html="company.htmlFormatName"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch, ref, reactive } from 'vue'
import { _getViewBrand } from '@/utils/storage'
import ClassNames from 'classnames'
import { fuzzyQueryCompany } from '@/api'
import { debounce } from '@/utils'
import { onClickOutside, useElementBounding } from '@vueuse/core'

export default defineComponent({
  props: {
    company: {
      type: String,
      default: ''
    },
    taxId: {
      type: String,
      default: ''
    },
    bindingEl: {
      type: HTMLElement,
      default: void 0
    }
  },
  emits: ['choose'],
  setup(props, { emit }) {
    /**
     * 当前编译的品牌
     */
    const brand = _getViewBrand()
    const getKeywordColor = () => {
      const config = {
        taier: '#D59E57',
        song: '#FFA53A',
        laimeili: '#BB3B29',
        jiumaojiu: '#BB3B29',
        sxym: '#efd5ab',
        'super-tomato': '#e23922',
        'mountain-outside': '#409610'
      }
      return config[brand] || '#BB3B29'
    }
    /**
     * 绑定的输入框尺寸
     */
    const { left } = useElementBounding(computed(() => props.bindingEl))
    /**
     * 是否展示待选抬头列表
     */
    const isShowList = ref(false)
    /**
     * 模糊查询结果列表
     */
    const companyList = ref([])
    /**
     * 列表元素
     */
    const companyListEl = ref(null)
    /**
     * 当前选中的公司
     */
    const choosedCompany = reactive({
      name: '',
      taxId: ''
    })
    /**
     * 待选抬头列表类名
     */
    const listClassName = computed(() => ClassNames('company-list', {
      'is-actived': isShowList.value,
      'is-song': brand === 'song',
      'is-beauty': brand === 'laimeili',
      'is-taier': brand === 'taier',
      'is-jmj': brand === 'jiumaojiu',
      'is-sxym': brand === 'sxym',
      'is-super-tomato': brand === 'super-tomato',
      'is-mountain-outside': brand === 'mountain-outside'
    }))

    const listStyle = computed(() => ({
      left: `${left.value}px`
    }))

    /**
     * 根据输入的公司名称进行模糊搜索
     */
    const toggleFuzzyQuery = debounce(async () => {
      const companyName = props.company
      const isSelected = choosedCompany.name === companyName && choosedCompany.taxId === props.taxId
      if (!companyName || isSelected) {
        companyList.value = []
        isShowList.value = false
        return
      }
      try {
        const { data } = await fuzzyQueryCompany({ companyName })
        companyList.value = Array.isArray(data) ? markCompanyName(companyName, data) : []
        const listLength = companyList.value.length
        if (listLength > 0) {
          const { name, taxId } = companyList.value[0]
          const isMatchOnlyOneResult = listLength === 1 && name === companyName && taxId === props.taxId
          isShowList.value = !isMatchOnlyOneResult
        }
      } catch (e) {
        console.log(e)
      }
    }, 600)

    /**
     * 处理抬头被选中
     * @param {*} company 
     */
    const onCompanyClick = (company) => {
      emit('choose', company)
      isShowList.value = false
      choosedCompany.name = company.name
      choosedCompany.taxId = company.taxId
    }

    /**
     * 对当前模糊匹配的关键字进行标记
     * @param {*} list 
     */
    const markCompanyName = (SearchCompany, list) => {
      if (!SearchCompany) return list
      return list.map(company => {
        let htmlFormatName = String(company.name)
        const serchStrPosition = htmlFormatName.indexOf(SearchCompany)
        if (serchStrPosition > -1) {
          const markText = `<span class="mark" style="color: ${getKeywordColor()};">${SearchCompany}</span>`
          htmlFormatName = `${htmlFormatName.slice(0, serchStrPosition)}${markText}${htmlFormatName.slice(serchStrPosition + SearchCompany.length, htmlFormatName.length)}`
        }
        return {
          ...company,
          htmlFormatName
        }
      })
    }

    /**
     * 监听抬头输入框值变化
     */
    watch(() => props.company, toggleFuzzyQuery)

    /**
     * 监听不处于列表中的点击事件
     */
    onClickOutside(companyListEl, (e) => {
      if (e.target !== props.bindingEl && isShowList.value) {
        isShowList.value = false
      } else if (e.target === props.bindingEl && !isShowList.value) {
        toggleFuzzyQuery()
      }
    })

    return {
      listClassName,
      listStyle,
      companyListEl,
      companyList,
      onCompanyClick,
      markCompanyName
    }
  }
})
</script>

<style lang="scss" scoped>
.company-list-wrapper {
  position: relative;

  .company-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
    padding-left: 100px;
    padding-right: 15px;
    box-sizing: border-box;
    text-align: left;
    z-index: 20;
    max-height: 180px;
    overflow: auto;
    font-size: 14px;

    &::-webkit-scrollbar {
      display: none;
    }

    &.is-actived {
      opacity: 1;
      visibility: visible;
    }

    .company {
      position: relative;

      // &:last-child {
      //   &::after {
      //     display: none;
      //   }
      // }
      &::after {
          content: '';
          position: absolute;
          transform: scaleY(0.5);
          bottom: 0;
        }
    }

    &.is-taier {
      background-color: #fff;
      color: #727171;

      .company {
        padding: 8px 0;
        
        &::after {
          left: -10px;
          right: 0;
          border-bottom: 1PX solid #cdcdcd;
        }
      }
    }

    &.is-song {
      background-color: #1C393F;
      color: #fff;
      max-height: 165px;
      .company {
        padding: 10px 0;
        
        &::after {
          left: -10px;
          right: 0;
          border-bottom: 1PX dashed rgba(239, 243, 238, .2);
        }
      }
    }

    &.is-beauty {
      background-color: #F0E7C6;
      color: #231916;
      max-height: 168px;
      .company {
        padding: 12px 0;
        
        &::after {
          left: -10px;
          right: 0;
          border-bottom: 1PX dashed rgba(35, 25, 22, 0.2);
        }
      }
    }

    &.is-jmj, &.is-super-tomato {
      background-color: #F1F1F1;
      color: #727171;
      max-height: 138px;
      padding-left: 85px;
      .company {
        margin: 12px 0;
      }
    }

    &.is-sxym {
      background-color: #0f0907;
      color: #fff;
      .company {
        padding: 12px 0;
        &::after {
          left: -10px;
          right: 0;
          border-bottom: 1PX solid #cdcdcd;
        }
      }
    }

    &.is-mountain-outside {
      background-color: #fff;
      color: #000;
      .company {
        padding: 12px 0;
        &::after {
          left: -10px;
          right: 0;
          border-bottom: 1PX dashed #020202;
        }
      }
    }
  }
}
</style>