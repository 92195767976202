import { render } from "./invoiceForm.vue?vue&type=template&id=f4a4899e&scoped=true"
import script from "./invoiceForm.vue?vue&type=script&lang=js"
export * from "./invoiceForm.vue?vue&type=script&lang=js"

import "./invoiceForm.vue?vue&type=style&index=0&id=f4a4899e&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-f4a4899e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "f4a4899e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f4a4899e', script)) {
    api.reload('f4a4899e', script)
  }
  
  module.hot.accept("./invoiceForm.vue?vue&type=template&id=f4a4899e&scoped=true", () => {
    api.rerender('f4a4899e', render)
  })

}

script.__file = "src/components/invoiceForm.vue"

export default script