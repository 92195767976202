import { render } from "./index.vue?vue&type=template&id=53903be9&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=53903be9&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-53903be9"
/* hot reload */
if (module.hot) {
  script.__hmrId = "53903be9"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('53903be9', script)) {
    api.reload('53903be9', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=53903be9&scoped=true", () => {
    api.rerender('53903be9', render)
  })

}

script.__file = "src/views/main/laimeili/index.vue"

export default script