import { render } from "./index.vue?vue&type=template&id=1036cfac&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=1036cfac&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1036cfac"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1036cfac"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1036cfac', script)) {
    api.reload('1036cfac', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=1036cfac&scoped=true", () => {
    api.rerender('1036cfac', render)
  })

}

script.__file = "src/views/tips/super-tomato/index.vue"

export default script