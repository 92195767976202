<template>
  <div class="taier">
    <div class="wrapper">
      <div class="tips">电子发票与纸质发票具有同等法律效力，可支持报销入账</div>
      <img
        class="form-title"
        src="@/assets/images/taier/form-title.png"
        alt=""
      />
      <div class="main">
        <invoice-form
          ref="formRef"
          v-model:showMore="showMore"
          @validate="(val) => (canSubmit = val)"
          :showColon="false"
        >
          <template v-slot:more>
            <more-info v-model="showMore" />
          </template>
        </invoice-form>
        <submit-btn
          :disabled="!canSubmit"
          :is-fixed="!showMore"
          @submit="onSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import InvoiceForm from "@/components/invoiceForm.vue";
import MoreInfo from "@/components/moreInfo.vue";
import SubmitBtn from "@/components/submitBtn.vue";

export default defineComponent({
  components: {
    InvoiceForm,
    MoreInfo,
    SubmitBtn,
  },
  setup() {
    const showMore = ref(false);
    const canSubmit = ref(false);
    const formRef = ref(null);
    const onSubmit = () => formRef.value.submitForm();

    return {
      showMore,
      canSubmit,
      formRef,
      onSubmit,
    };
  },
});
</script>

<style lang="less" scoped>
.taier {
  height: 100vh;
  padding: 40px 8px 10px;
  box-sizing: border-box;
  background-color: #111;
  overflow: hidden;

  .wrapper {
    background: url("~@/assets/images/taier/bg.png") no-repeat center;
    background-size: cover;
    height: 100%;
    text-align: center;
    overflow: auto;

    .tips {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      z-index: 9;
      color: #d59e57;
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: hidden;
      padding: 0 5px;
    }

    .form-title {
      width: 250px;
      margin-top: 40px;
    }

    .main {
      padding: 30px 15px 100px;

      ::v-deep(.van-cell-group) {
        background: none;

        .van-cell {
          padding: 24px 16px 4px;
          background: none;

          .van-field__label {
            color: #000000;
            font-weight: 600;
            margin-right: 0;
            font-size: 17px;
          }

          &::after {
            left: 90px;
            border-color: #d4d5d5;
          }

          &:last-child::after {
            display: block;
          }

          .radio-icon {
            width: 24px;
            transform: translateY(-2px);
          }

          .van-radio__label {
            margin-left: 4px;
          }
        }
      }

      ::v-deep(.invoiceTitle) {
        .chooseTitle {
          padding-left: 5px;
          color: #d59e57;
        }
      }
    }
  }
}
</style>
