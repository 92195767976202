import { createApp } from "vue";
import App from "./App.vue";
import Router from "@/router";
import "@/assets/style/common.scss";
import "lib-flexible/flexible";
import VantPlugin from "@/config/vant";
import { isIOS } from "@/utils/index";
import { _setOrgUrl } from "@/utils/storage";
// import VConsole from "vconsole";

// if (process.env.NODE_ENV === "staging") new VConsole();

if (isIOS()) {
  // 在ios下记录原始的地址
  _setOrgUrl(window.location.href.split("#")[0]);
}

createApp(App)
  .use(Router)
  .use(VantPlugin)
  .mount("#app");
