// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/taier/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".taier[data-v-45ba0e50] {\n  height: 100vh;\n  padding: 1.06667rem 0.21333rem 0.26667rem;\n  box-sizing: border-box;\n  background-color: #111;\n  overflow: hidden;\n}\n.taier .wrapper[data-v-45ba0e50] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: cover;\n  height: 100%;\n  text-align: center;\n  overflow: auto;\n}\n.taier .wrapper .tips[data-v-45ba0e50] {\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  height: 1.06667rem;\n  font-size: 0.37333rem;\n  line-height: 1.06667rem;\n  text-align: center;\n  z-index: 9;\n  color: #d59e57;\n  overflow-x: auto;\n  white-space: nowrap;\n  overflow-y: hidden;\n  padding: 0 0.13333rem;\n}\n.taier .wrapper .form-title[data-v-45ba0e50] {\n  width: 6.66667rem;\n  margin-top: 1.06667rem;\n}\n.taier .wrapper .main[data-v-45ba0e50] {\n  padding: 0.8rem 0.4rem 2.66667rem;\n}\n.taier .wrapper .main[data-v-45ba0e50] .van-cell-group {\n  background: none;\n}\n.taier .wrapper .main[data-v-45ba0e50] .van-cell-group .van-cell {\n  padding: 0.64rem 0.42667rem 0.10667rem;\n  background: none;\n}\n.taier .wrapper .main[data-v-45ba0e50] .van-cell-group .van-cell .van-field__label {\n  color: #000000;\n  font-weight: 600;\n  margin-right: 0;\n  font-size: 0.45333rem;\n}\n.taier .wrapper .main[data-v-45ba0e50] .van-cell-group .van-cell::after {\n  left: 2.4rem;\n  border-color: #d4d5d5;\n}\n.taier .wrapper .main[data-v-45ba0e50] .van-cell-group .van-cell:last-child::after {\n  display: block;\n}\n.taier .wrapper .main[data-v-45ba0e50] .van-cell-group .van-cell .radio-icon {\n  width: 0.64rem;\n  transform: translateY(-0.05333rem);\n}\n.taier .wrapper .main[data-v-45ba0e50] .van-cell-group .van-cell .van-radio__label {\n  margin-left: 0.10667rem;\n}\n.taier .wrapper .main[data-v-45ba0e50] .invoiceTitle .chooseTitle {\n  padding-left: 0.13333rem;\n  color: #d59e57;\n}\n", ""]);
// Exports
module.exports = exports;
