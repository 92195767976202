import { ref } from 'vue'
import { _getInvoicePdfUrl, _getFullInvoiceInfo } from '@/utils/storage'
import { ImagePreview } from 'vant'
import { fullCanDownloadFileMethodFields } from '@/config/business'

export default function (props) {
  const detail = ref([
    { label: '发票类型', value: '', field: 'type' },
    { label: '开票状态', value: '', field: 'currentStatus' },
    { label: '发票金额', value: '', field: 'totalPrice' },
    { label: '发票抬头', value: '', field: 'buyerName' },
    { label: '申请时间', value: '', field: 'issuedTime' },
    { label: '邮箱地址', value: '', field: 'buyerEmail' },
  ])
  const canDownloadFileMethodFields = ref([])

  const previewInvoice = () => {
    const fileUrl = _getInvoicePdfUrl()
    if (fileUrl.includes('.pdf')) {
      /**
       * 兼容旧的发票预览格式
       */
      window.location.href = fileUrl
    } else {
      ImagePreview([fileUrl])
    }
  }

  const setDetailValue = () => {
    const info = _getFullInvoiceInfo()
    info && detail.value.forEach(row => {
      const { field } = row
      const value = info[field]
      value && (row.value = field === 'totalPrice' ? `¥${value}` : value)
    })
  }

  const setCanDownloadFileMethodFields = () => {
    const info = _getFullInvoiceInfo()
    const fields = []
    fullCanDownloadFileMethodFields.forEach(field => {
      const val = info[field]
      if (typeof val === 'string' && val.length > 0) {
        fields.push(field)
      }
    })
    canDownloadFileMethodFields.value = fields
  }

  const init = () => {
    if (props.tipsType === 'success') {
      setDetailValue()
      setCanDownloadFileMethodFields()
    }
  }

  init()

  return {
    detail,
    canDownloadFileMethodFields,
    previewInvoice
  }
}