import { render } from "./index.vue?vue&type=template&id=62c3a112&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=62c3a112&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-62c3a112"
/* hot reload */
if (module.hot) {
  script.__hmrId = "62c3a112"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('62c3a112', script)) {
    api.reload('62c3a112', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=62c3a112&scoped=true", () => {
    api.rerender('62c3a112', render)
  })

}

script.__file = "src/views/tips/jiumaojiu/index.vue"

export default script